import React, { useEffect, useState } from 'react'
import { Tooltip, OverlayTrigger, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Loader from '../Loader/Loader'
import RVTable, { RVT_TABLE_SELECTION_TYPE } from '../Table/ReactVirtualizedTable'
import { updateRelativePermissions, calculatePermissions } from './utils'
import { PERMISSION_NOT_DEFINED_VALUE } from './permission-constant'
import { permissionsFullDescription } from 'constants/constants'
import PermissionTooltip from 'components/Tooltips/PermissionTooltip'

const PermissionTable = ({ data, height, permissions, fetchPermissions, onChange, useRadioButtonStatus = false }) => {
  const [newPermissions, setNewPermissions] = useState(data)

  useEffect(() => {
    setNewPermissions(data)
  }, [data])

  const getColumns = () => {
    const columns = [
      {
        key: 'label',
        label: 'label',
        width: 100,
        cellRenderer: data => {
          return (
            <OverlayTrigger placement="top" overlay={<Tooltip id={data.id}>{data.key}</Tooltip>}>
              <span>{data.label}</span>
            </OverlayTrigger>
          )
        }
      },
      {
        key: 'description',
        label: 'description',
        width: 400,
        cellRenderer: data => {
          const permissionsInfo = permissionsFullDescription[data.key]
          return (
            <>
              <span className="pr-1">{data.description}</span>
              {permissionsInfo && <PermissionTooltip permissionsInfo={permissionsInfo} id={data.id} />}
            </>
          )
        }
      }
    ]

    if (useRadioButtonStatus) {
      columns.push({
        key: 'value',
        label: 'Status',
        cellRenderer: rowData => {
          return (
            <ButtonToolbar>
              <ToggleButtonGroup
                type="radio"
                name="options"
                onChange={value => onCheckedValueChange(rowData.key, value)}
                value={rowData.value}
              >
                <ToggleButton
                  value={PERMISSION_NOT_DEFINED_VALUE}
                  title="Permission is not explicitly defined. It is inherited from the group's settings."
                >
                  Use Groups
                </ToggleButton>
                <ToggleButton value={false} title="Deny access to this module.">
                  Forbidden
                </ToggleButton>
                <ToggleButton value title="Access granted to this module.">
                  Allow
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          )
        }
      })
    } else {
      columns.push({
        key: 'value',
        label: 'Status',
        type: RVT_TABLE_SELECTION_TYPE.CHECKBOX
      })
    }

    return columns
  }

  const onCheckedValueChange = (key, val) => {
    setNewPermissions({
      ...newPermissions,
      [key]: val,
      // This behavior adds lower permission automatically when adding Admin in SupraQC and vice versa
      ...updateRelativePermissions({ key, val })
    })

    onChange({ [key]: val, ...updateRelativePermissions({ key, val }) })
  }

  if (!permissions) {
    return <Loader />
  }

  return (
    <div>
      <div>
        <RVTable
          enableSearch={false}
          data={calculatePermissions({
            permissions: permissions.data,
            newPermissions,
            defaultValue: useRadioButtonStatus ? PERMISSION_NOT_DEFINED_VALUE : false
          })}
          columns={getColumns()}
          height={height}
          remoteRowCount={permissions.count}
          fetchData={fetchPermissions}
          onCheckedValueChange={onCheckedValueChange}
        />
      </div>
    </div>
  )
}

PermissionTable.propTypes = {
  permissions: PropTypes.shape({
    data: PropTypes.object,
    count: PropTypes.number,
    offset: PropTypes.number
  }),
  data: PropTypes.object, // { 'abc': true, 'def': false }
  fetchPermissions: PropTypes.func,
  onChange: PropTypes.func,
  height: PropTypes.number,
  useRadioButtonStatus: PropTypes.bool
}

export default PermissionTable

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// components
import Box from '../Box'
import Input from '../Input'
import PersonIcon from '../Icon/Person'
import Loader from '../Loader'
import DocumentTitle from 'react-document-title'
// actions
import { verifyOnboardingToken, resendExpiredOnboardEmail } from '../../actions/auth-actions'
// utils
import { parse } from 'query-string'
import validateRouteProtocol from '../../utils/enforce-https'
import { appName } from '../../constants/constants'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

class OnboardingPage extends Component {
  state = {
    email: '',
    message: ''
  }

  componentDidMount() {
    validateRouteProtocol()

    if (!this.props.loading) {
      this.verifyTokenFromUrl()
    }
  }

  verifyTokenFromUrl() {
    const { email, token } = this.props
    this.props.verifyOnboardingToken({ email, token })
  }

  renderError() {
    const { error, email, resendExpiredOnboardEmail, token, resendEmailMessage } = this.props
    const { name = '', message = '' } = error
    let displayMesasge = 'Your onboarding link is invalid'
    let subText = null
    if (name === 'TokenExpiredError') {
      displayMesasge = resendEmailMessage || 'Your onboarding link has expired'
      subText = (
        <div>
          <Input
            className="email inputWithBackgroundColor"
            iconName={<PersonIcon width="24" height="24" />}
            type="text"
            value={email}
            required
            disabled
          />
          <input
            className="submit"
            value="Send new onboarding email"
            type="submit"
            onClick={() => resendExpiredOnboardEmail({ email, token })}
          />
        </div>
      )
    } else if (message.indexOf('already onboarded') > -1) {
      displayMesasge = 'User already onboarded'
      subText = (
        <span>
          Please {<a href="/login">Login</a>} using your email {<a href={`mailto:${email}`}>{email}</a>}. In case, you
          forgot your password, please use the {<a href="/forgot_password">Forgot Password</a>} page and we will send
          you a recovery link.
        </span>
      )
    } else if (message.startsWith('no active user found')) {
      displayMesasge = `No active user found for id: ${email}`
    } else if (name === 'unknownError') {
      displayMesasge = 'There was an error processing your request'
    }
    return (
      <div className="alignItemsCenter directionColumn flex">
        <h1 className="title">{displayMesasge}</h1>
        <div className="subtext">{subText}</div>
      </div>
    )
  }

  handleSubmitOnboardingRetry = event => {
    event.preventDefault()
    const { email, token, resendExpiredOnboardEmail } = this.props
    resendExpiredOnboardEmail({
      email,
      token
    })
    this.setState({
      message: `A new onboarding email has been sent to ${this.state.email}`
    })
  }

  updateInput = value => {
    this.setState({ email: value })
  }

  render() {
    const { loading, error } = this.props
    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <div>
          <form onSubmit={this.handleSubmitOnboardingRetry}>
            <Box className="authView alignItemsCenter justifyContentCenter flex">
              <Box className="authBox alignItemsCenter directionColumn flex">
                <>
                  {loading && <Loader height={45} />}
                  {error && this.renderError()}
                  <div className="footer" />
                </>
              </Box>
            </Box>
          </form>
        </div>
      </DocumentTitle>
    )
  }
}

OnboardingPage.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  email: PropTypes.string,
  token: PropTypes.string,
  resendEmailMessage: PropTypes.string,
  resendExpiredOnboardEmail: PropTypes.func,
  verifyOnboardingToken: PropTypes.func,
  documentTitle: PropTypes.string
}

OnboardingPage.defaultProps = {
  documentTitle: 'Onboarding'
}

function mapStateToProps({ session }) {
  const { isOnboarding: loading, resendEmail } = session
  let { error } = session
  if (error && typeof error === 'string') {
    error = { name: 'unknownError', message: '' }
  }
  const { email, token } = parse(window.location.search)
  return {
    loading,
    error,
    email,
    token,
    resendEmailMessage: resendEmail && resendEmail.message
  }
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, {
    resendExpiredOnboardEmail,
    verifyOnboardingToken
  })(OnboardingPage),
  'Onboarding page'
)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DocumentTitle from 'react-document-title'

// services
import UserService from '../../services/user-service'

// actions
import {
  fetchUser,
  saveUser,
  sendResetPasswordEmail,
  fetchUserPermissions,
  saveUserPermissions,
  resendOnboardingLink,
  disableUser,
  enableUser,
  deleteUser
} from '../../actions/user-actions'
import { updateFilter } from '../../actions/filter-actions'
import { fetchGroups, fetchGroupSuggestions } from '../../actions/group-actions'
import { updateSession, fetchPermissions } from '../../actions/app-actions'

// components
import Loader from '../Loader'
import HeaderUserEdit from './header'
import UserEditForm from './user-edit-form'
import PermissionTable from 'components/PermissionTable'
import userDisableModal from '../Modals/UserDisableModal/UserDisableModal'
import userDeleteModal from '../Modals/UserDeleteModal'
import PrimaryButton from 'components/buttons/PrimaryButton'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import { appName } from '../../constants/constants'

import './index.scss'

class UserEditPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      trackedUserPermissions: {}
    }
  }

  componentDidMount() {
    if (!this.props.user) return

    if (!UserService.isPortalAdmin(this.props.user) && !UserService.isGroupAdmin(this.props.user)) {
      // User can modify his own profile
      if (this.props.match.params.id !== undefined) {
        return this.props.history.replace('/')
      }
    }

    if (this.props.match.params.id === this.props.user.id) {
      return this.props.history.replace('/user')
    }

    // Only load user if :id != new
    if (this.props.userEditId && this.props.userEditId !== 'new') {
      this.props.fetchUser(this.props.userEditId)
      this.props.fetchUserPermissions(this.props.userEditId)
    }
    this.props.fetchPermissions(0, 50)
  }

  // Used on refresh
  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.id &&
      this.props.match.params.id !== 'new' &&
      this.props.loading &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      this.props.fetchUser(this.props.match.params.id)
    }
  }

  componentWillUnmount() {
    this.props.resetUser()
    this.setState({
      trackedUserPermissions: {}
    })
  }

  trackPermissionChange(newPermission) {
    this.setState(prevSt => ({
      ...prevSt,
      trackedUserPermissions: { ...prevSt.trackedUserPermissions, ...newPermission }
    }))
  }

  getDocumentTitle = () => {
    const { id, user, userEditId, newDocumentTitle, editDocumentTitle, welcomeDocumentTitle } = this.props

    if (!id && !user.isOnboarded) {
      return welcomeDocumentTitle
    }

    return userEditId === 'new' ? newDocumentTitle : editDocumentTitle
  }

  disableUser = (id, name) =>
    this.props.userDisableModal(name, () =>
      this.props.disableUser({ id }, () => this.props.history.replace('/admin/users'))
    )

  enableUser = id => this.props.enableUser({ id }, () => window.location.reload())

  deleteUser = (id, name) =>
    this.props.userDeleteModal(name, () =>
      this.props.deleteUser({ id }, () => this.props.history.replace('/admin/users'))
    )

  render() {
    const { id, user, groups, loading, userEdit, userEditId, groupSuggestions } = this.props

    if (loading) {
      return <Loader loading />
    }
    const documentTitle = this.getDocumentTitle()
    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <React.Fragment>
          <HeaderUserEdit user={user} id={userEditId} userEdit={userEdit} />
          <div className={'container form-container'}>
            {!id && !user.isOnboarded && (
              <div className="userWelcome">
                <div>Welcome!</div>
                <p>
                  Since this is your first time on the <span style={{ fontWeight: 'bold' }}>{`${appName}`}</span>{' '}
                  portal, please take some time to verify your profile details and set up your new password.
                </p>
              </div>
            )}

            <UserEditForm
              user={user}
              id={userEditId}
              groups={groups}
              userEdit={userEdit}
              session={this.props.session}
              saveUser={this.props.saveUser}
              disableUser={this.disableUser}
              enableUser={this.enableUser}
              deleteUser={this.deleteUser}
              groupSuggestions={groupSuggestions}
              onUpdateFilter={this.props.updateFilter}
              resetPassword={this.props.resetPassword}
              updateCurrentUser={this.props.updateCurrentUser}
              fetchGroupSuggestions={this.props.fetchGroupSuggestions}
              onResendOnboardingLink={this.props.onResendOnboardingLink}
              history={this.props.history}
            />

            {UserService.isPortalAdmin(user) && userEditId !== 'new' && userEdit.isOnboarded && (
              <div>
                <br />
                <div className="clearfix">
                  <h3>PERMISSIONS</h3>
                  <PrimaryButton
                    label="Save Permissions"
                    className="rightFormButton"
                    isDisabled={Object.keys(this.state.trackedUserPermissions).length === 0}
                    onClickHandler={() =>
                      this.props.saveUserPermissions(this.state.trackedUserPermissions, this.props.match.params.id)
                    }
                  />
                </div>
                <PermissionTable
                  permissions={this.props.permissions}
                  data={this.props.userPermissions}
                  fetchPermissions={this.props.fetchPermissions}
                  onChange={this.trackPermissionChange.bind(this)}
                  useRadioButtonStatus
                  height={750}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      </DocumentTitle>
    )
  }
}

UserEditPage.propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  user: PropTypes.object,
  groups: PropTypes.array,
  userEdit: PropTypes.object,
  saveUser: PropTypes.func,
  updateCurrentUser: PropTypes.func,
  hasActiveGroup: PropTypes.func,
  resetPassword: PropTypes.func,
  params: PropTypes.object,
  resetUser: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  fetchGroupSuggestions: PropTypes.func.isRequired,
  updateFilter: PropTypes.func,
  userEditId: PropTypes.string,
  groupSuggestions: PropTypes.array,
  isPortalAdmin: PropTypes.bool,
  fetchPermissions: PropTypes.func,
  saveUserPermissions: PropTypes.func,
  fetchUserPermissions: PropTypes.func,
  userPermissions: PropTypes.object,
  session: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.shape({
    data: PropTypes.object,
    count: PropTypes.number,
    offset: PropTypes.number
  }),
  onResendOnboardingLink: PropTypes.func,
  newDocumentTitle: PropTypes.string,
  editDocumentTitle: PropTypes.string,
  welcomeDocumentTitle: PropTypes.string,
  userDisableModal: PropTypes.string,
  userDeleteModal: PropTypes.func,
  disableUser: PropTypes.func,
  enableUser: PropTypes.func,
  deleteUser: PropTypes.func
}

UserEditPage.defaultProps = {
  newDocumentTitle: 'Create User',
  editDocumentTitle: 'Edit User',
  welcomeDocumentTitle: 'Welcome'
}

// Connect

function mapStateToProps(state, ownProps) {
  // TODO: fix this for new router
  const path = ownProps.match.path
  const { user, loading, userEdit, permissions } = state.session
  const id = path === '/admin/users/:id' ? ownProps.match.params.id : null
  return {
    id,
    session: state.session,
    loading: loading || !user,
    user,
    groups: state.groups.data,
    groupSuggestions: userEdit.groupSuggestions,
    userEditId: id,
    permissions,
    userPermissions: state.users.permissions ? state.users.permissions[id] : {},
    userEdit: id && id !== 'new' ? state.session.userEdit : {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: id => dispatch(fetchUser(id)),
    resetUser: () => dispatch(updateSession({ userEdit: {} })),
    fetchGroups: (offset, count, searchTerm) => dispatch(fetchGroups(offset, count, searchTerm)),
    fetchGroupSuggestions: searchTerm => dispatch(fetchGroupSuggestions(searchTerm)),
    updateFilter: (searchedText, filterId) => dispatch(updateFilter(searchedText, filterId)),
    updateCurrentUser: bindActionCreators(updateSession, dispatch),
    resetPassword: bindActionCreators(sendResetPasswordEmail, dispatch),
    saveUser: bindActionCreators(saveUser, dispatch),
    fetchPermissions: () => dispatch(fetchPermissions()),
    fetchUserPermissions: userId => dispatch(fetchUserPermissions(userId)),
    saveUserPermissions: (permissions, userId) => dispatch(saveUserPermissions(permissions, userId)),
    onResendOnboardingLink: email => dispatch(resendOnboardingLink(email)),
    userDisableModal: (user, group, callback) => dispatch(userDisableModal(user, group, callback)),
    userDeleteModal: (user, group, callback) => dispatch(userDeleteModal(user, group, callback)),
    disableUser: (data, callback) => dispatch(disableUser({ ...data, callback })),
    enableUser: (data, callback) => dispatch(enableUser({ ...data, callback })),
    deleteUser: (data, callback) => dispatch(deleteUser({ ...data, callback }))
  }
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, mapDispatchToProps)(UserEditPage),
  '"Admin User edit" page'
)

import React from 'react'
import { OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './styles.scss'

const PermissionTooltip = ({ permissionsInfo, id }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip bsSize="large" id={id}>
          <div>
            {permissionsInfo.map(label => {
              return <p style={{ textAlign: 'left', margin: '2px' }} key={label}>{`- ${label}`}</p>
            })}
          </div>
        </Tooltip>
      }
    >
      <Glyphicon glyph="info-sign" />
    </OverlayTrigger>
  )
}

PermissionTooltip.propTypes = {
  permissionsInfo: PropTypes.array,
  id: PropTypes.string
}

export default PermissionTooltip

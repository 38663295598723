import constants from '../../constants/niq-constants'
import controls from './controls'

export const controlElements = controls

export function isRuleGroup(rule) {
  return !!(rule.combinator && rule.rules)
}

/**
 * Util function to help recursively traverese the ruleGroup tree
 * and find rule/ruleGroup with a specific id
 * @export
 * @param {any} id
 * @param {any} parent
 * @returns
 */
export function findRule(id, parent) {
  if (parent.id === id) {
    return parent
  }

  for (const rule of parent.rules) {
    if (rule.id === id) {
      return rule
    } else if (isRuleGroup(rule)) {
      const subRule = findRule(id, rule)
      if (subRule) {
        return subRule
      }
    }
  }
}

/**
 * Util function to help recursively traverse the ruleGroup tree
 * and find parent of rule/ruleGroup with a specific id
 * @export
 * @param {any} id
 * @param {any} parent
 * @returns
 */
export function findParent(id, parent) {
  if (parent.id === id) {
    return null
  }

  for (const rule of parent.rules) {
    if (rule.id === id) {
      return parent
    } else if (isRuleGroup(rule)) {
      const subRule = findParent(id, rule)
      if (subRule) {
        return subRule
      }
    }
  }
}

export function areMultipleDescriptionsSelected(event, actionArgs) {
  // when event == 'onPropChange'
  // actionArgs == [propName, propValue, ruleId]
  const propName = actionArgs[0]
  const value = actionArgs[1]
  if (
    event === 'onPropChange' &&
    actionArgs &&
    (actionArgs.length === 3 || (actionArgs.length === 4 && actionArgs[3] === undefined)) &&
    propName === 'value' &&
    value &&
    typeof value === 'object'
  ) {
    return true
  }
  return false
}

/**
 * Util function to check if selected parent values and we need to fetch children
 * @param {string} event
 * @param {Array} actionArgs
 */
export function isParentValueSelected(event, actionArgs) {
  // when event == 'onPropChange'
  // actionArgs == [propName, propValue, ruleId]
  const propName = actionArgs[0]
  const value = actionArgs[1]
  if (
    event === 'onPropChange' &&
    actionArgs &&
    (actionArgs.length === 3 || (actionArgs.length === 4 && actionArgs[3] === undefined)) &&
    propName === 'value' &&
    typeof value === 'string' &&
    value.startsWith(constants.parentElementPrefix)
  ) {
    return true
  }
  return false
}

export function isValueWithConditionSelected(event, actionArgs) {
  // when event == 'onPropChange'
  // actionArgs == [propName, propValue, ruleId, condition]
  const propName = actionArgs[0]
  const value = actionArgs[1]
  const condition = actionArgs[3]
  if (
    event === 'onPropChange' &&
    actionArgs &&
    actionArgs.length === 4 &&
    propName === 'value' &&
    typeof value === 'string' &&
    typeof condition === 'string'
  ) {
    return true
  }
  return false
}

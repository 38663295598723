// @flow
import { forEach, cloneDeep, find, findIndex } from 'lodash'
import moment from 'moment'
import { findRule, findParent } from '../utils/portal-query-builder'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import constants, { searchToolTabs } from '../constants/niq-constants'
import {
  NIQ_CLOSE_ERROR_MESSAGE,
  NIQ_AUTOSUGGEST_SUCCEEDED,
  NIQ_SEARCH_TERM_SUGGEST,
  NIQ_FETCH_TYPE_CHILDREN_SUCCEEDED,
  NIQ_TOGGLE_TREE_VISIBILITY,
  NIQ_HIDE_TREE,
  NIQ_GET_SEARCH_METADATA_SUCCEEDED,
  NIQ_QUERY_TREE_CHANGED,
  NIQ_APPLY_QUERY,
  NIQ_CHANGE_CATEGORY_FILTER,
  NIQ_TOGGLE_LAYOUT_LOCK,
  NIQ_FETCH_SAVED_GROUP_SUCCEEDED,
  NIQ_INPUT_GROUP_CHANGE,
  NIQ_GROUP_SELECT,
  NIQ_CREATE_GROUP_SUCCEEDED,
  NIQ_FETCH_QUERIES_BY_GROUP_SUCCEEDED,
  NIQ_QUERY_SELECT,
  NIQ_CREATE_QUERY_SUCCEEDED,
  NIQ_INPUT_QUERY_CHANGE,
  NIQ_GET_PREVIOUS_QUERY,
  NIQ_GET_NEXT_QUERY,
  NIQ_SAVE_SEARCH_QUERY_SUCCEEDED,
  NIQ_DELETE_QUERY_SUCCEEDED,
  NIQ_DELETE_GROUP_SUCCEEDED,
  NIQ_UPDATE_QUERY_VALUE,
  NIQ_UPDATE_GROUP_VALUE,
  NIQ_GROUP_SEARCH_MODE,
  NIQ_QUERY_SEARCH_MODE,
  NIQ_SET_ACTIVE_SEARCH_TAB,
  NIQ_QUERY_RUN_STARTED,
  NIQ_UPDATE_QUERY_HEADER_EDIT_STATUS,
  NIQ_CLEAR_QUERY_TREE,
  NIQ_CLEAR_COMPLETE_QUERY,
  NIQ_CREATE_MULTIPLE_RULES,
  NIQ_GET_QUERY_SQL_STARTED,
  NIQ_GET_QUERY_SQL_SUCCEEDED,
  NIQ_GET_QUERY_SQL_FAILED,
  NIQ_CLEAN_UP_SEARCH_SUGGESTION,
  NIQ_AUTOSUGGEST_FAILED,
  NIQ_AUTOSUGGEST_LOADING_START
} from '../actions/niq-search-actions'
import {
  NIQ_SEARCH_TOGGLE_LOCK_DATA,
  NIQ_DESCRIPTION_SAVE_EDITS,
  NIQ_DESCRIPTION_SAVE_EDITS_SUCCEEDED,
  NIQ_SEARCH_FILTER_CHANGED,
  NIQ_TOGGLE_WIDGET_ENABLED_STATUS,
  NIQ_TOGGLE_DESCRIPTION_SEARCH_STATUS
} from '../actions/niq-widget-actions'
import { NIQ_QC_QUERY_RUN_STARTED } from '../actions/niq-qc-actions'
import { LOGOUT_SUCCESS } from 'actions/auth-actions'
import { ES_SCORECARD, ES_SCORECARD_KEY, SEARCH_KEY_ALL } from 'constants/constants'

const { EDIT_MODE, QC_MODE, TRENDS, METRICS } = searchToolTabs
const {
  DESCRIPTION,
  TIME,
  BRAND,
  MERCHANT,
  CATEGORY,
  DESCRIPTION_FIRST_WORD,
  STATS,
  DICTIONARY_COVERAGE,
  QC_MONITORING,
  SLICE_CATEGORY,
  SLICE_BRAND,
  INVALID_CATEGORY,
  INVALID_BRAND,
  OMNISALES_MODULE
} = constants.aggregations

const defaultQueryTree = {
  combinator: 'and',
  id: 'g-default',
  rules: [
    {
      field: 'description',
      id: 'r-default',
      operator: 'contains',
      value: ''
    }
  ]
}
const defaultSearchToolGroup = {
  list: [],
  selectedValue: '',
  selectedID: null,
  inputValue: '',
  updatedValue: ''
}
const defaultSearchToolQuery = {
  list: [],
  selectedValue: '',
  selectedID: null,
  inputValue: '',
  updatedValue: ''
}

const initialSidebarState = {
  seq: 2,
  errorMessage: 'A',
  searchValue: '',
  isSuggestionsLoading: false,
  searchSuggestions: [],
  isTreeVisible: true,
  defaultQueryTree: cloneDeep(defaultQueryTree),
  queryTree: cloneDeep(defaultQueryTree),
  selectedId: null,
  isLocked: false,
  isLayoutLocked: true,
  isStale: {
    [EDIT_MODE]: false,
    [QC_MODE]: false,
    [TRENDS]: false,
    [METRICS]: false
  },
  queryHeader: {
    currentIndex: -1,
    group: cloneDeep(defaultSearchToolGroup),
    query: cloneDeep(defaultSearchToolQuery)
  },
  widgetEnabledStatus: {
    [DESCRIPTION]: true,
    [CATEGORY]: true,
    [BRAND]: true,
    [MERCHANT]: true,
    [DESCRIPTION_FIRST_WORD]: true,
    [STATS]: false, // IT doesn't work for NIQ IN-1810
    [SLICE_CATEGORY]: true,
    [SLICE_BRAND]: true,
    [INVALID_CATEGORY]: true,
    [INVALID_BRAND]: true,
    [OMNISALES_MODULE]: true
  },
  isQueryHeaderEdited: false,
  showDescSearch: false,
  lastLoadDate: {
    searchData: null,
    searchDataScorecard: null
  },
  searchFilters: {
    /** fallback from and to values if min max values are not obtained from backend */
    from: moment()
      .subtract(3, 'months')
      .format(constants.dateFormat.qcTool),
    to: moment().format(constants.dateFormat.qcTool),
    dateRangeInit: true,
    userTertile: '0', // 0,1,2,3 (0-All)
    brandFilters: {
      [SEARCH_KEY_ALL]: true,
      brandsInDict: true,
      brandsNotInDict: true,
      brandsInStaging: true,
      brandsNotInStaging: true,
      brandsEditable: true,
      brandsNotEditable: true
    },
    categoryFilters: {
      [SEARCH_KEY_ALL]: true,
      categoriesInDict: true,
      categoriesNotInDict: true,
      categoriesInStaging: true,
      categoriesNotInStaging: true,
      categoriesEditable: true,
      categoriesNotEditable: true
    },
    includeZeroRevenue: true, // include items with zero revenue
    categoryFilterList: [],
    [ES_SCORECARD_KEY]: ES_SCORECARD.NIQ,
    selectedBrandUsers: [],
    selectedCategoryUsers: []
  },
  brandUpdateUsers: [],
  categoryUpdateUsers: [],
  isActiveTab: EDIT_MODE,
  querySQLLoading: false,
  querySQL: ''
}

const searchReducer = (state = initialSidebarState, action) => {
  let group
  let query
  let selectedQuery
  let currentIndex
  let enabledQueries
  switch (action.type) {
    case NIQ_APPLY_QUERY:
      return {
        ...state,
        isTreeVisible: false,
        isStale: {
          [EDIT_MODE]: true,
          [QC_MODE]: true,
          [TRENDS]: true,
          [METRICS]: true
        },
        queryHeader: {
          ...state.queryHeader
        }
      }

    case NIQ_GET_QUERY_SQL_STARTED:
      return {
        ...state,
        querySQLLoading: true
      }
    case NIQ_GET_QUERY_SQL_SUCCEEDED:
      return {
        ...state,
        querySQLLoading: false,
        querySQL: action.data
      }
    case NIQ_GET_QUERY_SQL_FAILED:
      return {
        ...state,
        querySQLLoading: false,
        querySQL: ''
      }

    case NIQ_QUERY_TREE_CHANGED:
      return {
        ...state,
        queryTree: action.queryTree
      }
    case NIQ_CREATE_MULTIPLE_RULES: {
      const rule = findRule(action.ruleId, state.queryTree)
      const newRules = action.values.map((value, index) => ({
        ...rule,
        id: `${rule.id}-${index}`,
        value
      }))
      const parent = findParent(rule.id, state.queryTree)
      if (action.event === 'addInCurrentGroup') {
        const index = findIndex(parent.rules, { id: rule.id })
        // replace the current rule with new rules
        parent.rules.splice(index, 1, ...newRules)
      } else if (action.event === 'addNewRuleGroup') {
        // convert current rule to a ruleGroup
        // with newRules as its childRules
        rule.id = `g-${rule.id}`
        // TODO: dynamically make combinator 'or' or 'and'
        rule.combinator = 'or'
        rule.ruleGroupLabel = action.ruleGroupLabel
        rule.rules = newRules
        rule.isMinimized = true
        rule.value = null
      }
      return {
        ...state,
        searchValue: '',
        searchSuggestions: [],
        /**
         * since the rule in queryTree which was modified, could be at any level,
         * we need to deep clone the entire tree
         */
        queryTree: cloneDeep(state.queryTree)
      }
    }

    case NIQ_FETCH_TYPE_CHILDREN_SUCCEEDED: {
      // find rule object from the tree
      const rule = findRule(action.ruleId, state.queryTree)
      const childRules = []
      // create child rule for each value
      action.data.forEach((element, index) => {
        childRules.push({
          ...rule,
          id: `${rule.id}-${index}`,
          value: element.value
        })
      })
      /**
       * convert current rule to a rule group.
       * Also, since we haven't modified the original rule object, no need to reassign it to queryTree
       */
      rule.id = `g-${rule.id}`
      rule.combinator = rule.operator === 'matches' ? 'or' : 'and'
      rule.ruleGroupLabel = action.ruleGroupLabel
      rule.rules = childRules
      rule.isMinimized = true
      return {
        ...state,
        /**
         * since the rule in queryTree which was modified, could be at any level,
         * we need to deep clone the entire tree
         */
        queryTree: cloneDeep(state.queryTree)
      }
    }
    case NIQ_SEARCH_FILTER_CHANGED:
      let filters
      let isStale = {}
      switch (action.key) {
        case 'range':
          filters = {
            ...state.searchFilters,
            from: moment(action.value[0]).format(constants.dateFormat.qcTool),
            to: moment(action.value[1]).format(constants.dateFormat.qcTool)
          }
          isStale = {
            [EDIT_MODE]: true,
            [TRENDS]: true,
            [METRICS]: true
          }
          break

        case 'brandFilters':
          filters = {
            ...state.searchFilters,
            brandFilters: { ...action.value }
          }
          break

        case 'categoryFilters':
          filters = {
            ...state.searchFilters,
            categoryFilters: { ...action.value }
          }
          break

        case ES_SCORECARD_KEY:
          filters = {
            ...state.searchFilters,
            [ES_SCORECARD_KEY]: action.value
          }
          break

        case 'userTertile':
          filters = {
            ...state.searchFilters,
            userTertile: action.value
          }
          break

        case 'includeZeroRevenue':
          filters = {
            ...state.searchFilters,
            includeZeroRevenue: !state.searchFilters.includeZeroRevenue
          }
          break

        case 'brand_added_by':
        case 'category_added_by':
          const { selectedBrandUsers, selectedCategoryUsers } = state.searchFilters
          const { value, key } = action
          let filterKey = ''
          let userList = []
          let allUpdatedByUsers = []
          if (key === 'brand_added_by') {
            userList = [...selectedBrandUsers]
            allUpdatedByUsers = [...state.brandUpdateUsers]
            filterKey = 'selectedBrandUsers'
          } else {
            userList = [...selectedCategoryUsers]
            allUpdatedByUsers = [...state.categoryUpdateUsers]
            filterKey = 'selectedCategoryUsers'
          }

          if (value === 'all users') {
            if (userList.includes(value)) {
              userList = []
            } else {
              userList = allUpdatedByUsers
            }
          } else {
            if (userList.includes(value)) {
              userList.splice(userList.indexOf(value), 1)
              if (userList.length === allUpdatedByUsers.length - 1) {
                userList.splice(userList.indexOf('all users'), 1)
              }
            } else if (userList.length === allUpdatedByUsers.length - 2) {
              userList.push(value)
              userList.push('all users')
            } else {
              userList.push(value)
            }
          }
          filters = {
            ...state.searchFilters,
            [filterKey]: userList
          }
          break

        default:
          filters = { ...state.searchFilters }
          break
      }
      return {
        ...state,
        searchFilters: filters,
        isStale: {
          ...state.isStale,
          ...isStale
        }
      }

    case NIQ_DESCRIPTION_SAVE_EDITS:
    case NIQ_DESCRIPTION_SAVE_EDITS_SUCCEEDED:
      return {
        ...state,
        isLocked: false
      }

    case NIQ_SEARCH_TOGGLE_LOCK_DATA:
      return {
        ...state,
        isLocked: !state.isLocked
      }

    case NIQ_SEARCH_TERM_SUGGEST:
      return { ...state, searchValue: action.data }

    case NIQ_CHANGE_CATEGORY_FILTER:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          categoryFilterList: [...action.filterList]
        }
      }

    case NIQ_FETCH_SAVED_GROUP_SUCCEEDED:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            list: action.data
          }
        }
      }

    case NIQ_INPUT_GROUP_CHANGE:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            inputValue: action.inputValue
          }
        }
      }

    case NIQ_GROUP_SELECT:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            selectedID: action.selectedGroup[0].id,
            selectedValue: action.selectedGroup[0].label,
            updatedValue: action.selectedGroup[0].label
          },
          currentIndex: -1,
          query: cloneDeep(defaultSearchToolQuery)
        }
      }

    case NIQ_CREATE_GROUP_SUCCEEDED:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            selectedID: action.data.id,
            selectedValue: action.data.name,
            updatedValue: action.data.name,
            inputValue: action.data.name
          }
        }
      }

    case NIQ_FETCH_QUERIES_BY_GROUP_SUCCEEDED:
      currentIndex = findIndex(action.data, {
        id: state.queryHeader.query.selectedID
      })

      query = state.queryHeader.query
      if (!action.clear) {
        currentIndex = -1
        query = {
          selectedValue: '',
          selectedID: null,
          inputValue: '',
          updatedValue: ''
        }
      }
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex,
          query: {
            ...query,
            list: action.data
          }
        }
      }

    case NIQ_QUERY_SELECT:
      query = state.queryHeader.query
      selectedQuery = action.selectedQuery[0]
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: findIndex(query.list, {
            name: selectedQuery.label
          }),
          query: {
            ...state.queryHeader.query,
            selectedID: selectedQuery.id,
            selectedValue: selectedQuery.label,
            updatedValue: selectedQuery.label
          }
        },
        queryTree: JSON.parse(find(query.list, { id: selectedQuery.id }).query)
      }

    case NIQ_CREATE_QUERY_SUCCEEDED:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          query: {
            ...state.queryHeader.query,
            selectedID: action.data.id,
            selectedValue: action.data.name,
            updatedValue: action.data.name,
            inputValue: action.data.name
          }
        }
      }

    case NIQ_INPUT_QUERY_CHANGE:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          query: {
            ...state.queryHeader.query,
            inputValue: action.inputValue
          }
        }
      }

    case NIQ_GET_PREVIOUS_QUERY:
      currentIndex = state.queryHeader.currentIndex
      query = state.queryHeader.query
      let previousIndex = currentIndex
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (!query.list[i].isDisable) {
          previousIndex = i
          break
        }
      }
      if (currentIndex === previousIndex) {
        return state
      }

      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: previousIndex,
          query: {
            ...state.queryHeader.query,
            selectedID: query.list[previousIndex].id,
            selectedValue: query.list[previousIndex].name,
            updatedValue: query.list[previousIndex].name,
            inputValue: query.list[previousIndex].name
          }
        },
        queryTree: JSON.parse(find(query.list, { id: query.list[previousIndex].id }).query)
      }

    case NIQ_GET_NEXT_QUERY:
      currentIndex = state.queryHeader.currentIndex
      query = state.queryHeader.query
      let nextIndex = currentIndex
      for (let i = currentIndex + 1; i < query.list.length; i++) {
        if (!query.list[i].isDisable) {
          nextIndex = i
          break
        }
      }
      if (currentIndex === nextIndex) {
        return state
      }
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: nextIndex,
          query: {
            ...state.queryHeader.query,
            selectedID: query.list[nextIndex].id,
            selectedValue: query.list[nextIndex].name,
            updatedValue: query.list[nextIndex].name,
            inputValue: query.list[nextIndex].name
          }
        },
        queryTree: JSON.parse(find(query.list, { id: query.list[nextIndex].id }).query)
      }

    case NIQ_CLEAR_COMPLETE_QUERY:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: -1,
          query: cloneDeep(defaultSearchToolQuery),
          group: {
            ...state.queryHeader.group,
            selectedID: null,
            selectedValue: '',
            updatedValue: '',
            inputValue: ''
          }
        },
        queryTree: cloneDeep(defaultQueryTree)
      }

    case NIQ_CLEAR_QUERY_TREE:
      return {
        ...state,
        queryTree: cloneDeep(defaultQueryTree)
      }

    case NIQ_SAVE_SEARCH_QUERY_SUCCEEDED:
      const groupList = state.queryHeader.group.list
      const groupIndex = findIndex(groupList, { id: action.data.group.id })
      const queryList = state.queryHeader.query.list
      const queryIndex = findIndex(queryList, { id: action.data.query.id })

      if (groupIndex >= 0) {
        groupList[groupIndex].name = action.data.group.name || ''
      }

      if (queryIndex >= 0) {
        queryList[queryIndex].name = action.data.query.name || ''
        queryList[queryIndex].query = JSON.stringify(action.data.queryTree) || cloneDeep(defaultQueryTree)
      }

      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            selectedValue: action.data.group.name,
            updatedValue: action.data.group.name,
            selectedID: action.data.group.id,
            inputValue: action.data.group.name,
            list: groupList
          },
          query: {
            ...state.queryHeader.query,
            selectedValue: action.data.query.name,
            updatedValue: action.data.query.name,
            selectedID: action.data.query.id,
            inputValue: action.data.query.name,
            list: queryList
          }
        },
        isQueryHeaderEdited: false
      }

    case NIQ_DELETE_QUERY_SUCCEEDED:
      query = state.queryHeader.query
      currentIndex = state.queryHeader.currentIndex
      enabledQueries = query.list.filter(data => !data.isDisable && data.id !== query.selectedID)
      if (enabledQueries.length === 0) {
        return {
          ...state,
          queryHeader: {
            ...state.queryHeader,
            currentIndex: -1,
            query: cloneDeep(defaultSearchToolQuery)
          },
          queryTree: cloneDeep(defaultQueryTree)
        }
      }
      let updatedIndex = currentIndex
      for (let i = currentIndex + 1; i < query.list.length; i++) {
        if (!query.list[i].isDisable) {
          updatedIndex = i
          break
        }
      }
      if (updatedIndex === currentIndex) {
        for (let i = currentIndex - 1; i >= 0; i--) {
          if (!query.list[i].isDisable) {
            updatedIndex = i
            break
          }
        }
      }
      const nextQuery = query.list[updatedIndex]
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: updatedIndex,
          query: {
            ...state.queryHeader.query,
            selectedValue: nextQuery ? nextQuery.name : '',
            updatedValue: nextQuery ? nextQuery.name : '',
            inputValue: nextQuery ? nextQuery.name : '',
            selectedID: nextQuery ? nextQuery.id : null
          }
        },
        queryTree: nextQuery ? JSON.parse(nextQuery.query) : cloneDeep(defaultQueryTree)
      }

    case NIQ_UPDATE_QUERY_VALUE:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          query: {
            ...state.queryHeader.query,
            updatedValue: action.value
          }
        }
      }

    case NIQ_UPDATE_GROUP_VALUE:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            updatedValue: action.value
          }
        }
      }

    case NIQ_GROUP_SEARCH_MODE:
      group = state.queryHeader.group
      query = state.queryHeader.query
      currentIndex = state.queryHeader.currentIndex
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            updatedValue: group.selectedValue
          },
          query: {
            ...state.queryHeader.query,
            updatedValue: query.selectedValue
          }
        },
        queryTree: currentIndex >= 0 ? JSON.parse(query.list[currentIndex].query) : cloneDeep(defaultQueryTree)
      }

    case NIQ_QUERY_SEARCH_MODE:
      query = state.queryHeader.query
      currentIndex = state.queryHeader.currentIndex
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          query: {
            ...state.queryHeader.query,
            updatedValue: query.selectedValue
          }
        },
        queryTree: currentIndex >= 0 ? JSON.parse(query.list[currentIndex].query) : cloneDeep(defaultQueryTree)
      }

    case NIQ_DELETE_GROUP_SUCCEEDED:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: -1,
          group: {
            ...state.queryHeader.group,
            selectedValue: '',
            selectedID: null,
            inputValue: '',
            updatedValue: ''
          },
          query: cloneDeep(defaultSearchToolQuery)
        },
        queryTree: cloneDeep(defaultQueryTree)
      }

    case NIQ_AUTOSUGGEST_LOADING_START:
      return { ...state, isSuggestionsLoading: true }

    case NIQ_AUTOSUGGEST_SUCCEEDED:
      return {
        ...state,
        searchSuggestions: action.data,
        isSuggestionsLoading: false
      }

    case NIQ_AUTOSUGGEST_FAILED:
      return {
        ...state,
        searchSuggestions: [],
        isSuggestionsLoading: false
      }

    case NIQ_CLOSE_ERROR_MESSAGE:
      return { ...state, errorMessage: null }

    case NIQ_SET_ACTIVE_SEARCH_TAB:
      return {
        ...state,
        isActiveTab: action.payload
      }

    case NIQ_TOGGLE_TREE_VISIBILITY:
      // TODO: move following to component & trigger based on isTreeVisible param change
      setTimeout(function() {
        forEach(ReactHighstock.Highcharts.charts, chart => {
          if (chart) {
            chart.setSize(null, null, false)
          }
        })
      }, 0)
      return { ...state, isTreeVisible: !state.isTreeVisible }

    case NIQ_HIDE_TREE:
      return { ...state, isTreeVisible: false }

    case NIQ_TOGGLE_LAYOUT_LOCK: {
      return {
        ...state,
        isLayoutLocked: !state.isLayoutLocked
      }
    }

    case LOGOUT_SUCCESS:
      return initialSidebarState

    case NIQ_QUERY_RUN_STARTED:
      const { aggType } = action
      // DESCRIPTION query has run it means EDIT_MODE data is no longer stale
      if (aggType === DESCRIPTION) {
        return {
          ...state,
          isStale: {
            ...state.isStale,
            [EDIT_MODE]: false
          }
        }
        // TIME query has run it means TRENDS data is no longer stale
      } else if (aggType === TIME) {
        return {
          ...state,
          isStale: {
            ...state.isStale,
            [TRENDS]: false
          }
        }
      } else if (aggType === DICTIONARY_COVERAGE) {
        return {
          ...state,
          isStale: {
            ...state.isStale,
            [METRICS]: false
          }
        }
      } else if (aggType === QC_MONITORING) {
        return {
          ...state,
          isStale: {
            ...state.isStale,
            [METRICS]: false
          }
        }
      }
      return state
    case NIQ_QC_QUERY_RUN_STARTED:
      // QC_QUERY_RUN_STARTED query has run it means QC_MODE data is no longer stale
      return {
        ...state,
        isStale: {
          ...state.isStale,
          [QC_MODE]: false
        }
      }
    case NIQ_UPDATE_QUERY_HEADER_EDIT_STATUS:
      return {
        ...state,
        isQueryHeaderEdited: action.status
      }
    case NIQ_TOGGLE_WIDGET_ENABLED_STATUS:
      return {
        ...state,
        widgetEnabledStatus: {
          ...state.widgetEnabledStatus,
          [action.widgetType]: !state.widgetEnabledStatus[action.widgetType]
        }
      }
    case NIQ_TOGGLE_DESCRIPTION_SEARCH_STATUS:
      return {
        ...state,
        showDescSearch: !state.showDescSearch
      }
    case NIQ_GET_SEARCH_METADATA_SUCCEEDED:
      const { dataRefresh, dateRange, brandUpdateUsers, categoryUpdateUsers } = action.data
      return {
        ...state,
        lastLoadDate: {
          ...state.lastLoadDate,
          searchNIQData: dataRefresh.searchNIQLoad
        },
        searchFilters: {
          ...state.searchFilters,
          min_range: dateRange.min_range,
          max_range: dateRange.max_range,
          from: dateRange.from,
          to: dateRange.to,
          dateRangeInit: dateRange.dateRangeInit
        },
        brandUpdateUsers: ['all users', ...brandUpdateUsers],
        categoryUpdateUsers: ['all users', ...categoryUpdateUsers]
      }

    case NIQ_CLEAN_UP_SEARCH_SUGGESTION: {
      return {
        ...state,
        searchSuggestions: []
      }
    }

    default:
      return state
  }
}

export default searchReducer

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import cx from 'classnames'
import DocumentTitle from 'react-document-title'

import { appName } from '../../constants/constants'

// components
import Unauthorized from '../Unauthorized'
import RVTable from '../Table/ReactVirtualizedTable'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

// actions
import { updateFilter } from '../../actions/filter-actions'
import { saveGroup, fetchGroups, downloadGroups } from '../../actions/group-actions'

// service
import UserService from '../../services/user-service'

// css
import './index.scss'

class GroupsPage extends Component {
  constructor(props) {
    super(props)

    this.searchEntities = {
      groups: { display: 'Groups' }
    }
  }

  componentDidMount() {
    if (this.props.isPortalAdmin) {
      this.props.fetchGroups(0, 25, '')
    }
  }

  changeStatus = group => {
    this.props.saveGroup({
      groupData: {
        isActive: !group.isActive,
        id: group.id
      }
    })
  }

  getColumns() {
    return [
      {
        key: 'group',
        label: 'Group',
        cellRenderer: group => (
          <span>
            <b>{group.name}</b>
          </span>
        )
      },
      {
        key: 'users',
        label: 'Users',
        cellRenderer: group => <span>{`${group.userCount} / ${group.seatCount} Users`}</span>
      },
      {
        key: 'products',
        label: 'Products',
        cellRenderer: group => {
          const categoriesCount = group.CategoriesCount
          return (
            <span>
              {categoriesCount === 0 ? 'No' : categoriesCount}
              {categoriesCount === 1 ? ' Product' : ' Products'}
            </span>
          )
        }
      },
      {
        key: 'feeds',
        label: 'Feeds',
        cellRenderer: group => {
          const feedsCount = group.feedCount
          return (
            <span>
              {feedsCount === 0 ? 'No' : feedsCount}
              {feedsCount === 1 ? ' Feed' : ' Feeds'}
            </span>
          )
        }
      },
      {
        key: 'edit',
        label: 'Edit',
        cellRenderer: group => {
          return (
            <span>
              <Link to={`/admin/groups/${group.id}`}>Edit</Link>
              {' - '}
              <Link to={`/admin/groups/${group.id}/new`}>New Edit</Link>
            </span>
          )
        }
      },
      {
        key: 'active',
        label: 'Active',
        cellRenderer: group => {
          return (
            <div className="groupActive">
              {!group.isPortalGroup && (
                <div
                  onClick={this.changeStatus.bind(this, group)}
                  className={cx('statusButton', group.isActive ? 'active' : 'inactive')}
                >
                  <div className={'statusMarker'} />
                </div>
              )}
            </div>
          )
        }
      }
    ]
  }

  handleDownload = clickEvent => {
    clickEvent.preventDefault()
    this.props.downloadGroups(this.props.search)
  }

  render() {
    const { isPortalAdmin, groups, documentTitle } = this.props

    if (!isPortalAdmin) {
      return <Unauthorized />
    }

    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <div className="GroupsPage__container container">
          <div className="textRight" style={{ paddingBottom: '20px' }}>
            <Link className="btn_custom" to="/admin/groups/new">
              Add Group
            </Link>

            <Link className="btn_custom" to="/admin/groups/new/new">
              Add Group (New)
            </Link>

            <button
              onClick={this.handleDownload}
              className="btn_custom_secondary download-csv-button-custom pull-right"
            >
              Download CSV
            </button>
          </div>

          <RVTable
            enableSearch
            data={groups}
            minimumBatchSize={25}
            query={this.props.query}
            search={this.props.search}
            columns={this.getColumns()}
            selectedSearchEntity={'groups'}
            onSearchEntityChanged={() => {}}
            fetchData={this.props.fetchGroups}
            updateFilter={this.props.updateFilter}
            remoteRowCount={this.props.groupsCount}
            searchEntities={this.searchEntities}
          />
        </div>
      </DocumentTitle>
    )
  }
}

GroupsPage.propTypes = {
  saveGroup: PropTypes.func,
  groups: PropTypes.array,
  groupsCount: PropTypes.number,
  search: PropTypes.string,
  query: PropTypes.object,
  isPortalAdmin: PropTypes.bool,
  fetchGroups: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  documentTitle: PropTypes.string,
  downloadGroups: PropTypes.func.isRequired
}

GroupsPage.defaultProps = {
  documentTitle: 'Groups'
}

// Connect

function mapStateToProps(state, ownProps) {
  return {
    groups: state.groups.data,
    groupsCount: state.groups.count,
    search: state.filter.groups,
    query: ownProps.location.query,
    isPortalAdmin: state.session.user && UserService.isPortalAdmin(state.session.user)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateFilter: (text, id) => dispatch(updateFilter(text, 'groups')),
    downloadGroups: searchTerm => dispatch(downloadGroups(searchTerm)),
    saveGroup: (data, callback) => dispatch(saveGroup({ ...data, callback })),
    fetchGroups: (offset, count, search) => dispatch(fetchGroups(offset, count, search))
  }
}

export default WithErrorBoundaryWrapper(connect(mapStateToProps, mapDispatchToProps)(GroupsPage), '"Admin Groups" page')

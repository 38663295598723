export const NIQ_AUTOSUGGEST_LOADING_START = 'NIQ_AUTOSUGGEST_LOADING_START'
export const NIQ_AUTOSUGGEST_SUCCEEDED = 'NIQ_AUTOSUGGEST_SUCCEEDED'
export const NIQ_AUTOSUGGEST_FAILED = 'NIQ_AUTOSUGGEST_FAILED'
export const NIQ_CLOSE_ERROR_MESSAGE = 'NIQ_CLOSE_ERROR_MESSAGE'
export const NIQ_QUERY_RUN_STARTED = 'NIQ_QUERY_RUN_STARTED'
export const NIQ_QUERY_RUN_SUCCEEDED = 'NIQ_QUERY_RUN_SUCCEEDED'
export const NIQ_QUERY_RUN_FAILED = 'NIQ_QUERY_RUN_FAILED'
export const NIQ_QUERY_SET_QUALITY = 'NIQ_QUERY_SET_QUALITY'
export const NIQ_SEARCH_TERM_SUGGEST = 'NIQ_SEARCH_TERM_SUGGEST'
export const NIQ_QUERY_TREE_CHANGED = 'NIQ_QUERY_TREE_CHANGED'
export const NIQ_APPLY_QUERY = 'NIQ_APPLY_QUERY'
export const NIQ_LOAD_DEFAULT_QUERY = 'NIQ_LOAD_DEFAULT_QUERY'
export const NIQ_FETCH_TYPE_CHILDREN = 'NIQ_FETCH_TYPE_CHILDREN'
export const NIQ_FETCH_TYPE_CHILDREN_SUCCEEDED = 'NIQ_FETCH_TYPE_CHILDREN_SUCCEEDED'
export const NIQ_FETCH_TYPE_CHILDREN_FAILED = 'NIQ_FETCH_TYPE_CHILDREN_FAILED'
export const NIQ_TOGGLE_QC_MODE = 'NIQ_TOGGLE_QC_MODE'
export const NIQ_TOGGLE_TREE_VISIBILITY = 'NIQ_TOGGLE_TREE_VISIBILITY'
export const NIQ_HIDE_TREE = 'NIQ_HIDE_TREE'
export const NIQ_GET_SEARCH_METADATA = 'NIQ_GET_SEARCH_METADATA'
export const NIQ_GET_SEARCH_METADATA_SUCCEEDED = 'NIQ_GET_SEARCH_METADATA_SUCCEEDED'
export const NIQ_GET_SEARCH_METADATA_FAILED = 'NIQ_GET_SEARCH_METADATA_FAILED'
export const NIQ_CHANGE_CATEGORY_FILTER = 'NIQ_CHANGE_CATEGORY_FILTER'
export const NIQ_TOGGLE_LAYOUT_LOCK = 'NIQ_TOGGLE_LAYOUT_LOCK'
export const NIQ_FETCH_SAVED_GROUP = 'NIQ_FETCH_SAVED_GROUP'
export const NIQ_FETCH_SAVED_GROUP_SUCCEEDED = 'NIQ_FETCH_SAVED_GROUP_SUCCEEDED'
export const NIQ_FETCH_SAVED_GROUP_FAILED = 'NIQ_FETCH_SAVED_GROUP_FAILED'
export const NIQ_INPUT_GROUP_CHANGE = 'NIQ_INPUT_GROUP_CHANGE'
export const NIQ_GROUP_SELECT = 'NIQ_GROUP_SELECT'
export const NIQ_GROUP_CREATE = 'NIQ_GROUP_CREATE'

export const NIQ_CREATE_GROUP_SUCCEEDED = 'NIQ_CREATE_GROUP_SUCCEEDED'
export const NIQ_CREATE_GROUP_FAILED = 'NIQ_CREATE_GROUP_FAILED'
export const NIQ_FETCH_QUERIES_BY_GROUP = 'NIQ_FETCH_QUERIES_BY_GROUP'
export const NIQ_FETCH_QUERIES_BY_GROUP_SUCCEEDED = 'NIQ_FETCH_QUERIES_BY_GROUP_SUCCEEDED'
export const NIQ_FETCH_QUERIES_BY_GROUP_FAILED = 'NIQ_FETCH_QUERIES_BY_GROUP_FAILED'

export const NIQ_QUERY_SELECT = 'NIQ_QUERY_SELECT'
export const NIQ_QUERY_CREATE = 'NIQ_QUERY_CREATE'

export const NIQ_CREATE_QUERY_SUCCEEDED = 'NIQ_CREATE_QUERY_SUCCEEDED'
export const NIQ_CREATE_QUERY_FAILED = 'NIQ_CREATE_QUERY_FAILED'
export const NIQ_INPUT_QUERY_CHANGE = 'NIQ_INPUT_QUERY_CHANGE'
export const NIQ_GET_PREVIOUS_QUERY = 'NIQ_GET_PREVIOUS_QUERY'
export const NIQ_GET_NEXT_QUERY = 'NIQ_GET_NEXT_QUERY'
export const NIQ_DELETE_GROUP = 'NIQ_DELETE_GROUP'
export const NIQ_DELETE_GROUP_SUCCEEDED = 'NIQ_DELETE_GROUP_SUCCEEDED'
export const NIQ_DELETE_GROUP_FAILED = 'NIQ_DELETE_GROUP_FAILED'
export const NIQ_DELETE_QUERY = 'NIQ_DELETE_QUERY'
export const NIQ_DELETE_QUERY_SUCCEEDED = 'NIQ_DELETE_QUERY_SUCCEEDED'
export const NIQ_DELETE_QUERY_FAILED = 'NIQ_DELETE_QUERY_FAILED'
export const NIQ_RESET_QUERY_HEADER = 'NIQ_RESET_QUERY_HEADER'
export const NIQ_SAVE_SEARCH_QUERY = 'NIQ_SAVE_SEARCH_QUERY'
export const NIQ_SAVE_SEARCH_QUERY_SUCCEEDED = 'NIQ_SAVE_SEARCH_QUERY_SUCCEEDED'
export const NIQ_SAVE_SEARCH_QUERY_FAILED = 'NIQ_SAVE_SEARCH_QUERY_FAILED'
export const NIQ_UPDATE_QUERY_VALUE = 'NIQ_UPDATE_QUERY_VALUE'
export const NIQ_UPDATE_GROUP_VALUE = 'NIQ_UPDATE_GROUP_VALUE'
export const NIQ_GROUP_SEARCH_MODE = 'NIQ_GROUP_SEARCH_MODE'
export const NIQ_QUERY_SEARCH_MODE = 'NIQ_QUERY_SEARCH_MODE'
export const NIQ_QUERY_STATS_STARTED = 'NIQ_QUERY_STATS_STARTED'
export const NIQ_QUERY_STATS_SUCCEEDED = 'NIQ_QUERY_STATS_SUCCEEDED'
export const NIQ_QUERY_STATS_FAILED = 'NIQ_QUERY_STATS_FAILED'
export const NIQ_STAT_FILTER_CHANGED = 'NIQ_STAT_FILTER_CHANGED'
export const NIQ_SET_ACTIVE_SEARCH_TAB = 'NIQ_SET_ACTIVE_SEARCH_TAB'
export const NIQ_UPDATE_QUERY_HEADER_EDIT_STATUS = 'NIQ_UPDATE_QUERY_HEADER_EDIT_STATUS'
export const NIQ_CLEAR_QUERY_TREE = 'NIQ_CLEAR_QUERY_TREE'
export const NIQ_CLEAR_COMPLETE_QUERY = 'NIQ_CLEAR_COMPLETE_QUERY'
export const NIQ_CREATE_MULTIPLE_RULES = 'NIQ_CREATE_MULTIPLE_RULES'
export const NIQ_GET_QUERY_SQL = 'NIQ_GET_QUERY_SQL'
export const NIQ_GET_QUERY_SQL_STARTED = 'NIQ_GET_QUERY_SQL_STARTED'
export const NIQ_GET_QUERY_SQL_SUCCEEDED = 'NIQ_GET_QUERY_SQL_SUCCEEDED'
export const NIQ_GET_QUERY_SQL_FAILED = 'NIQ_GET_QUERY_SQL_FAILED'
export const NIQ_CLEAN_UP_SEARCH_SUGGESTION = 'CLEAN_UP_SEARCH_SUGGESTION'

export function loadDefaultQuery() {
  return {
    type: NIQ_LOAD_DEFAULT_QUERY
  }
}
export function searchTermSuggest({ term, filter, excludeParents, excludeInactive }) {
  return {
    type: NIQ_SEARCH_TERM_SUGGEST,
    data: term,
    filter,
    excludeParents,
    excludeInactive
  }
}
export function changeCategoryFilter(filterList) {
  return {
    type: NIQ_CHANGE_CATEGORY_FILTER,
    filterList
  }
}
export function getSearchMetaData() {
  return {
    type: NIQ_GET_SEARCH_METADATA
  }
}
export function fetchTypeChildren(type, value, id, condition) {
  return {
    type: NIQ_FETCH_TYPE_CHILDREN,
    data: {
      type,
      value,
      id,
      condition
    }
  }
}

export function toggleQCMode() {
  return {
    type: NIQ_TOGGLE_QC_MODE
  }
}

export function setSelectedTab(payload) {
  return {
    type: NIQ_SET_ACTIVE_SEARCH_TAB,
    payload
  }
}

export function toggleTreeVisibility() {
  return {
    type: NIQ_TOGGLE_TREE_VISIBILITY
  }
}

export function hideTree() {
  return {
    type: NIQ_HIDE_TREE
  }
}

export function handleQueryTreeChanged(queryTree, event, actionArgs) {
  return {
    type: NIQ_QUERY_TREE_CHANGED,
    queryTree,
    event,
    actionArgs
  }
}

export function applyQuery(payload = {}) {
  return {
    type: NIQ_APPLY_QUERY,
    payload
  }
}

export function toggleLayoutLock() {
  return {
    type: NIQ_TOGGLE_LAYOUT_LOCK
  }
}

export function getAllGroup() {
  return {
    type: NIQ_FETCH_SAVED_GROUP
  }
}

export function onInputGroupChange(inputValue) {
  return {
    type: NIQ_INPUT_GROUP_CHANGE,
    inputValue
  }
}

export function onGroupChange(selectedGroup) {
  return {
    type: NIQ_GROUP_SELECT,
    selectedGroup
  }
}

export function onGroupCreate(value) {
  return {
    type: NIQ_GROUP_CREATE,
    value
  }
}

export function fetchQueries() {
  return {
    type: NIQ_FETCH_QUERIES_BY_GROUP
  }
}

export function onQueryChange(selectedQuery) {
  return {
    type: NIQ_QUERY_SELECT,
    selectedQuery
  }
}

export function onQueryCreate(value) {
  return {
    type: NIQ_QUERY_CREATE,
    value
  }
}

export function onInputQueryChange(inputValue) {
  return {
    type: NIQ_INPUT_QUERY_CHANGE,
    inputValue
  }
}

export function getPreviousQuery() {
  return {
    type: NIQ_GET_PREVIOUS_QUERY
  }
}

export function getNextQuery() {
  return {
    type: NIQ_GET_NEXT_QUERY
  }
}

export function onDeleteGroup() {
  return {
    type: NIQ_DELETE_GROUP
  }
}

export function onDeleteQuery() {
  return {
    type: NIQ_DELETE_QUERY
  }
}

export function resetQueryHeader() {
  return {
    type: NIQ_RESET_QUERY_HEADER
  }
}

export function saveSearchQuery() {
  return {
    type: NIQ_SAVE_SEARCH_QUERY
  }
}

export function onUpdatedQueryValue(value) {
  return {
    type: NIQ_UPDATE_QUERY_VALUE,
    value
  }
}

export function onUpdatedGroupValue(value) {
  return {
    type: NIQ_UPDATE_GROUP_VALUE,
    value
  }
}

export function onClickGroupSearch() {
  return {
    type: NIQ_GROUP_SEARCH_MODE
  }
}

export function onClickQuerySearch() {
  return {
    type: NIQ_QUERY_SEARCH_MODE
  }
}

export function onStatFilterChanged(selectedFilter) {
  return {
    type: NIQ_STAT_FILTER_CHANGED,
    selectedFilter
  }
}

export function updateQueryHeaderEditStatus(status) {
  return {
    type: NIQ_UPDATE_QUERY_HEADER_EDIT_STATUS,
    status
  }
}

export function clearQueryTree() {
  return {
    type: NIQ_CLEAR_QUERY_TREE
  }
}

export function getQuerySQL(queryTree) {
  return {
    type: NIQ_GET_QUERY_SQL,
    queryTree
  }
}

export function clearCompleteQuery() {
  return {
    type: NIQ_CLEAR_COMPLETE_QUERY
  }
}

export function createMultipleRules(event, ruleId, values) {
  return {
    type: NIQ_CREATE_MULTIPLE_RULES,
    event,
    ruleId,
    values
  }
}

export function cleanupSearchSuggestion() {
  return {
    type: NIQ_CLEAN_UP_SEARCH_SUGGESTION
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isBoolean, isString } from 'lodash'
import { OverlayTrigger, Button, Glyphicon, Tooltip } from 'react-bootstrap'

import SearchTypeahead from '../../../../../components/SearchTypeahead'
import DescriptionValueEditor from './DescriptionValueEditor'
import BooleanValueEditor from './BooleanValueEditor'
import constants, {
  validEmailTypes,
  validSources,
  LIMIT_EXTENDED_SEARCH_RESULTS
} from './../../../constants/niq-constants'
import { CODING_MODE, ITEM_TYPE, VALIDATION_FLAG } from 'components/NIQSearchPage/config'

/**
 *  NOTE: this is copy of  "src/utils/portal-query-builder/controls/ValueEditor.js"
 *  Modified to use NIQ search actions and constants
 */
import { searchTermSuggest } from '../../../actions/niq-search-actions'
import { NIQ_SEARCH_STORE_PATH } from '../../../reducers/niq-reducers'

const LIMITATION_MESSAGE = `Note: Due to a limitation, you can only insert up to the first ${LIMIT_EXTENDED_SEARCH_RESULTS} values with this selection.`

function mapStateToProps(state, ownProps) {
  const searchValue = state[NIQ_SEARCH_STORE_PATH].searchValue
  const isSuggestionsLoading = state[NIQ_SEARCH_STORE_PATH].isSuggestionsLoading
  const searchSuggestions = state[NIQ_SEARCH_STORE_PATH].searchSuggestions
  const hasSuggestions = searchSuggestions?.length > 0

  const extendedSearchFields = ['category', 'brand']
  let extendedSearches = []
  if (!isSuggestionsLoading && hasSuggestions && extendedSearchFields.includes(ownProps.field)) {
    extendedSearches = [
      {
        value: searchValue,
        prefixLabel: 'Select contains: ',
        info: LIMITATION_MESSAGE,
        condition: constants.searchConditions.CONTAINS
      },
      {
        value: searchValue,
        prefixLabel: 'Starts with: ',
        info: LIMITATION_MESSAGE,
        condition: constants.searchConditions.STARTS_WITH
      }
    ]
  }

  return {
    searchValue,
    isSuggestionsLoading: state[NIQ_SEARCH_STORE_PATH].isSuggestionsLoading,
    searchSuggestions: state[NIQ_SEARCH_STORE_PATH].searchSuggestions.reduce((suggArray, suggestion) => {
      const isParentSuggestion = isParent(suggestion)
      if (isParentSuggestion) {
        suggArray.push({
          ...suggestion,
          info: LIMITATION_MESSAGE,
          prefixLabel: constants.parentElementPrefix
        })
      }

      suggArray.push({
        ...suggestion,
        isChild: true,
        prefixLabel: suggestion.isActive === false ? 'Select Inactive: ' : isParentSuggestion ? 'Select Parent: ' : ''
      })
      return suggArray
    }, extendedSearches)
  }
}

function mapDispatchToProps(dispatch, _ownProps) {
  return {
    onSearchTermSuggest: ({ term, filter }) => {
      dispatch(searchTermSuggest({ term, filter, excludeInactive: true }))
    }
  }
}

function isParent(suggestion) {
  return (
    suggestion.type &&
    constants.fields[suggestion.type].canBeParent &&
    suggestion.isChild !== null &&
    !suggestion.isChild
  )
}

class NIQCustomValueEditor extends Component {
  static propTypes = {
    field: PropTypes.string,
    value: PropTypes.any,
    searchValue: PropTypes.string,
    handleOnChange: PropTypes.func,
    onSearchTermSuggest: PropTypes.func,
    isSuggestionsLoading: PropTypes.bool,
    searchSuggestions: PropTypes.arrayOf(PropTypes.object),
    readOnly: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      inEdit: false,
      inputText: '',
      autoLoading: true,
      minLength: 1
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.field !== prevProps.field) {
      this.setState({ autoLoading: true, minLength: 1 })
    }

    const fieldsToAutoLoad = [CODING_MODE, ITEM_TYPE, VALIDATION_FLAG]

    if (this.state.autoLoading && fieldsToAutoLoad.includes(this.props.field)) {
      this.setState({ autoLoading: false, minLength: 0 })
      this.props.onSearchTermSuggest({ term: '', filter: this.props.field })
    }
  }

  renderMenuItemChildren(option, _props, _idx) {
    return (
      <span>
        <span>{`${option.prefixLabel ? option.prefixLabel : ''}`}</span>
        <span style={{ fontWeight: 'bold' }}>{option.value}</span>
        {option.info && (
          <OverlayTrigger overlay={<Tooltip id="in-edit-value-info">{option.info}</Tooltip>} placement="right">
            <Glyphicon glyph="info-sign" className="ml-4" />
          </OverlayTrigger>
        )}
      </span>
    )
  }

  render() {
    const tooltip = name => <Tooltip id="in-edit-value-clear">{name}</Tooltip>
    const isValueExist = (isString(this.props.value) && this.props.value.length > 0) || isBoolean(this.props.value)
    if (this.props.readOnly || (isValueExist && !this.state.inEdit)) {
      if (typeof this.props.value === 'string' || typeof this.props.value === 'boolean') {
        return (
          <div
            className="qb-applied-value"
            onDoubleClick={e => {
              e.preventDefault()
              this.setState({ inEdit: true, inputText: this.props.value })
            }}
          >
            {this.props.value.toString()}
          </div>
        )
      } else {
        return <div>{`...`}</div>
      }
    }
    const onInputChange = text => {
      this.setState({ inputText: text })
      this.props.onSearchTermSuggest({
        term: text,
        filter: this.props.field
      })
    }

    const onChange = selectionList => {
      if (selectionList && Array.isArray(selectionList) && selectionList.length > 0) {
        const selected = selectionList[0]
        if (Array.isArray(selected.value)) {
          this.props.handleOnChange(selected)
        } else if (selected.condition) {
          this.props.handleOnChange(selected.value, selected.condition)
        } else if (isParent(selected)) {
          this.props.handleOnChange(constants.parentElementPrefix + selected.value)
        } else {
          this.props.handleOnChange(selected.value)
        }
        this.setState({ inEdit: false })
      }
    }
    let suggestions = this.props.searchSuggestions
    const descriptionValueEditorFields = [
      'description',
      'from_addr',
      'subject',
      'domain',
      'template',
      'nankey',
      'rct_code'
    ]
    const booleanValueEditorFields = ['invalid_category', 'invalid_brand']

    switch (this.props.field) {
      case 'description':
      case 'subject':
      case 'from_addr':
      case 'domain':
      case 'template':
      case 'nankey':
      case 'rct_code':
        suggestions = []
        break
      case 'email_type':
      case 'bbx_supra':
        const email_types = validEmailTypes.map(type => type.toLowerCase())
        suggestions = email_types.map(email_type => {
          return {
            type: this.props.field,
            value: email_type,
            label: constants.fields[this.props.field].label
          }
        })

        break
      case 'price':
      case 'distinct_msg_uuid':
      case 'distinct_mailbox_uuid':
      case 'cnt_msg':
      case 'percentage_msg':
      case 'cnt_mailbox':
      case 'percentage_msg_total_cusum':
      case 'sales':
      case 'module_confidence':
      case 'subcategory_confidence':
      case 'segment_confidence':
      case 'brand_confidence':
        if (isNaN(this.state.inputText)) {
          suggestions = []
        } else {
          suggestions = [
            {
              type: this.props.field,
              value: this.state.inputText,
              label: constants.fields[this.props.field].label
            }
          ]
        }
        break
      case 'is_cfg':
      case 'source':
        const sources = validSources.map(source => source.toLowerCase())
        suggestions = sources.map(source => {
          return {
            type: this.props.field,
            value: source,
            label: constants.fields[this.props.field].label
          }
        })
        break
      default:
        break
    }

    return (
      <div className="qb-in-edit-value">
        {descriptionValueEditorFields.includes(this.props.field) ? (
          <DescriptionValueEditor inputText={this.state.inputText} onChange={onChange} onInputChange={onInputChange} />
        ) : booleanValueEditorFields.includes(this.props.field) ? (
          <BooleanValueEditor
            initialValue={isBoolean(this.state.inputText) ? this.state.inputText : undefined}
            onChange={onChange}
          />
        ) : (
          <SearchTypeahead
            placeholder={'Enter value'}
            selectedValues={[{ value: this.props.value }]}
            onInputChange={onInputChange}
            suggestions={suggestions}
            onChange={onChange}
            renderSuggestions={this.renderMenuItemChildren}
            autoClean
            isLoading={this.props.isSuggestionsLoading}
            minLength={this.state.minLength}
          />
        )}
        <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Cancel')}>
          <Button
            className="value-clear-button"
            bsStyle="link"
            onClick={event => {
              this.setState({ inEdit: false })
              onInputChange('')
              event.preventDefault()
            }}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </OverlayTrigger>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NIQCustomValueEditor)

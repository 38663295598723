import React from 'react'
import PropTypes from 'prop-types'

import { appName } from '../../constants/constants'
import './index.scss'
/*
 * This is component this will shows when page will crash with error
 */
function fallBack(pageName, { error, resetErrorBoundary }) {
  return (
    <div className="error-boundary">
      <div className="error-boundary-icon" />
      <h1 className="mt-3">{`Oops! An error occurred on the ${pageName}`}</h1>
      <h2>A report has been automatically created and sent to the {`${appName}`} team.</h2>
      <h2>
        You can try reloading the <a href={window.location.href}>previous page</a> or return <a href="/">Home</a>
      </h2>
      <div className="mt-3">
        <p>{`Error details: [${error.message}]`}</p>
        <p>{`URL: [${window.location.href}]`}</p>
      </div>
    </div>
  )
}

fallBack.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
  pageName: PropTypes.string
}

export default fallBack

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import PermissionTable from 'components/PermissionTable'
import { updateGroupForm, fetchGroupPermissions } from '../../../actions/group-edit-actions'

import { fetchPermissions } from '../../../actions/app-actions'

class PermissionsTab extends Component {
  componentDidMount() {
    if (isEmpty(this.props.permissions)) {
      this.props.fetchPermissions()
    }

    this.props.fetchGroupPermissions(this.props.groupId)
  }

  handlePermissionChange = (permission, enabled) => {
    const selectedPermissions = this.props.groupPermissions
    selectedPermissions[permission] = enabled

    this.props.updateGroupForm('permissions', selectedPermissions)
  }

  render() {
    return (
      <div className="group-edit-permissions-tab">
        <PermissionTable
          height={500}
          fetchPermissions={() => {}}
          data={this.props.groupPermissions}
          onChange={this.handlePermissionChange}
          permissions={{ data: this.props.permissions }}
        />
      </div>
    )
  }
}

PermissionsTab.propTypes = {
  groupId: PropTypes.string,
  permissions: PropTypes.object,
  updateGroupForm: PropTypes.func,
  fetchPermissions: PropTypes.func,
  groupPermissions: PropTypes.object,
  fetchGroupPermissions: PropTypes.func
}

PermissionsTab.defaultProps = {
  permissions: {},
  groupPermissions: {}
}

const mapStateToProps = ({ session: { permissions }, groupEdit: { groupForm } }) => ({
  permissions: permissions.data,
  groupPermissions: groupForm.permissions
})

export default connect(mapStateToProps, {
  updateGroupForm,
  fetchPermissions,
  fetchGroupPermissions
})(PermissionsTab)

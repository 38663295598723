import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'

import UserService from '../../services/user-service'
import { appName } from '../../constants/constants'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import './index.scss'

class AppHomePage extends Component {
  render() {
    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <div className="page-container">
          <h3>Slice Intelligence Portal</h3>
        </div>
      </DocumentTitle>
    )
  }
}

AppHomePage.propTypes = {
  userProducts: PropTypes.object,
  documentTitle: PropTypes.string
}

AppHomePage.defaultProps = {
  documentTitle: 'Dashboard'
}

function mapStateToProps(state) {
  return {
    userProducts: UserService.getProducts(state.session.user)
  }
}

export default WithErrorBoundaryWrapper(connect(mapStateToProps)(AppHomePage), 'Dashboard page')

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import { Prompt } from 'react-router'
import { Row, Col, Glyphicon } from 'react-bootstrap'
import { get } from 'lodash'
import Loader from '../Loader'
import { default as Errorbox } from './components/Errorbox'
import BasicDetails from './BasicDetails'
import FilterDetails from './FilterDetails'
import TabDetails from './TabDetails'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import {
  fetchReportForEdit,
  initializeNewReport,
  dismissError,
  discardChanges,
  saveReport,
  fetchReportFolders
} from '../../actions/custom-report-edit-actions'

import { appName } from '../../constants/constants'

import './index.scss'

const navigatePromptMsg = 'You have some unsaved changes. Are you sure you want to leave this page without saving?'

class CustomReportEditPage extends Component {
  componentDidMount() {
    this.initialize()
  }

  componentDidUpdate() {
    this.refetchReportIfNeeded()
  }

  initialize() {
    const { reportId, fetchReportForEdit, initializeNewReport, fetchReportFolders } = this.props
    if (!reportId) {
      return
    }
    if (reportId === 'new') {
      initializeNewReport()
      fetchReportFolders(0)
    } else {
      fetchReportForEdit(reportId)
    }
    window.scrollTo(0, 0)
  }

  refetchReportIfNeeded() {
    const { reportId, activeReportId, loading, fetchReportForEdit, initializeNewReport } = this.props
    if (reportId && reportId !== 'new' && !loading && reportId !== activeReportId) {
      fetchReportForEdit(reportId)
      window.scrollTo(0, 0)
    }
    if (reportId && reportId === 'new' && reportId !== activeReportId) {
      initializeNewReport()
      window.scrollTo(0, 0)
    }
  }

  backToReportsPage = () => {
    this.props.history.push('/admin/reports')
  }

  backToReportFoldersPage = () => {
    const { report: { folderId = 0 } = {}, history } = this.props
    history.push(`/admin/report/folders/${folderId}`)
  }

  previewReport = () => {
    this.props.history.push(`/admin/reports/view/${this.props.report.id}`)
  }

  isReportValid = () => {
    const { activeTabCount, basicDetails } = this.props
    if (activeTabCount === 0) return false
    const { id: reportId, isNameValid } = basicDetails
    const isNewReport = !reportId || reportId === 'new'
    if (
      // new report should have valid fields
      (isNewReport && !isNameValid) ||
      // old report should ensure fields are untouched or valid
      (!isNewReport && isNameValid === false)
    ) {
      return false
    }
    return true
  }

  hasChangesToSave = () => {
    const { basicDetails, dirtyTabCount, dirtyFilterCount } = this.props

    if (!basicDetails.dirty && dirtyTabCount === 0 && dirtyFilterCount === 0) {
      return false
    }
    return true
  }

  validateReportDetails(basicDetails = {}, tabs = {}) {
    const { id: reportId, isNameValid } = basicDetails
    const isNewReport = !reportId || reportId === 'new'
    if (
      // new report should have valid fields
      (isNewReport && !isNameValid) ||
      // old report should ensure fields are untouched or valid
      (!isNewReport && isNameValid === false)
    ) {
      return false
    }
    const remainingTabCount = Object.values(tabs).filter(tab => tab.action !== 'delete').length
    if (remainingTabCount === 0) {
      return false
    }
    return true
  }

  render() {
    const {
      loading,
      error,
      report,
      dismissError,
      discardChanges,
      saveReport,
      newDocumentTitle,
      editDocumentTitle
    } = this.props
    const { id, name } = report
    const isReportValid = this.isReportValid()
    const hasChangesToSave = this.hasChangesToSave()
    const isNewReport = !id || id === 'new'

    return (
      <DocumentTitle
        title={
          newDocumentTitle && editDocumentTitle
            ? `${appName} | ${isNewReport ? newDocumentTitle : editDocumentTitle}`
            : appName
        }
      >
        <div className="ctm-report-edit-page">
          {loading && <Loader overlap />}
          {error}
          {error && <Errorbox message={error} onDismiss={dismissError} />}
          <div>
            <Prompt when={hasChangesToSave} message={navigatePromptMsg} />
            <div className="report-form-header">
              <Row>
                <Col sm={12} className="report-action-row">
                  <div className="pull-left">
                    <button
                      className={'btn_custom_secondary btn_back'}
                      type="button"
                      onClick={this.backToReportFoldersPage}
                    >
                      <Glyphicon glyph="menu-left" /> Folders
                    </button>
                    <button className={'btn_custom_secondary btn_back'} type="button" onClick={this.backToReportsPage}>
                      <Glyphicon glyph="menu-left" /> Reports
                    </button>
                  </div>
                  <div className="pull-right">
                    <button
                      className={'btn_custom_secondary'}
                      disabled={!hasChangesToSave}
                      type="button"
                      onClick={discardChanges}
                    >
                      Discard Changes
                    </button>
                    <button
                      className={'btn_custom'}
                      type="button"
                      disabled={loading || !isReportValid || !hasChangesToSave}
                      onClick={saveReport}
                    >
                      {isNewReport ? 'Create' : 'Save'}
                    </button>
                    <button className={'btn_custom'} type="button" disabled={isNewReport} onClick={this.previewReport}>
                      View
                    </button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="pull-left">
                    <div className="report-id">ID: {id || '--'}</div>
                    <div className="report-name">{id === 'new' ? 'New Report' : name || '--'}</div>
                  </div>
                </Col>
              </Row>
            </div>
            <hr />
            <BasicDetails />
            <hr />
            <FilterDetails />
            <hr />
            <TabDetails />
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

function mapStateToProps({ reportEdit, session }, props) {
  const {
    activeReportId,
    report,
    loading,
    error,
    basicDetailsForm: basicDetails,
    filterForm: { filters = {} },
    tabForm: { tabs = {} }
  } = reportEdit
  return {
    reportId: get(props, ['match', 'params', 'id']),
    activeReportId,
    report,
    loading,
    error,
    user: session.user,
    basicDetails,
    dirtyTabCount: Object.values(tabs).filter(tab => tab.action).length,
    dirtyFilterCount: Object.values(filters).filter(filterObject => filterObject.action).length,
    activeTabCount: Object.values(tabs).filter(tab => tab.action !== 'delete').length
  }
}

CustomReportEditPage.propTypes = {
  reportId: PropTypes.string.isRequired,
  activeReportId: PropTypes.string,
  report: PropTypes.object,
  user: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
  fetchReportForEdit: PropTypes.func,
  initializeNewReport: PropTypes.func,
  dismissError: PropTypes.func,
  discardChanges: PropTypes.func,
  saveReport: PropTypes.func,
  history: PropTypes.object.isRequired,
  basicDetails: PropTypes.object,
  dirtyFilterCount: PropTypes.number,
  dirtyTabCount: PropTypes.number,
  activeTabCount: PropTypes.number,
  stagedTabs: PropTypes.object,
  newDocumentTitle: PropTypes.string,
  editDocumentTitle: PropTypes.string,
  fetchReportFolders: PropTypes.func
}

CustomReportEditPage.defaultProps = {
  editDocumentTitle: 'Edit Report',
  newDocumentTitle: 'New Report'
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, {
    fetchReportForEdit,
    initializeNewReport,
    dismissError,
    discardChanges,
    saveReport,
    fetchReportFolders
  })(CustomReportEditPage),
  '"Admin Custom Report Edit" page'
)

import { takeLatest, takeEvery, select, call, put, all } from 'redux-saga/effects'
import axios from 'axios'
import cuid from 'cuid'
import _, { keys, keyBy, map, groupBy, mapValues, chunk, get } from 'lodash'
import { delay } from 'redux-saga'
import moment from 'moment'

import { ES_SCORECARD_KEY } from 'constants/constants'
import constants, { searchToolTabs } from '../constants/niq-constants'

import config from 'config/index'

import {
  NIQ_FETCH_TYPE_CHILDREN_SUCCEEDED,
  NIQ_TOGGLE_QC_MODE,
  NIQ_APPLY_QUERY,
  NIQ_CHANGE_CATEGORY_FILTER,
  NIQ_SET_ACTIVE_SEARCH_TAB,
  NIQ_GET_SEARCH_METADATA
} from '../actions/niq-search-actions'

import {
  NIQ_QC_SPLIT_BY_CHANGED,
  NIQ_QC_SHOW_MORE_SPLITBY_CHARTS,
  NIQ_QC_DRILLDOWN_BY_CHANGED,
  NIQ_QC_QUERY_RUN_STARTED,
  NIQ_QC_QUERY_RUN_SUCCEEDED,
  NIQ_QC_QUERY_RUN_FAILED,
  NIQ_QC_TIME_INTERVAL_CHANGED,
  NIQ_QC_REFRESH_DESCRIPTION_DATA,
  NIQ_QC_SELECTED_TIME_RANGE_CHANGED,
  NIQ_QC_DESCRIPTION_COUNT_STARTED,
  NIQ_QC_DESCRIPTION_COUNT_SUCCEEDED,
  NIQ_QC_DESCRIPTION_COUNT_FAILED,
  NIQ_CHANGE_DESCRIPTION_ITEM_TYPE,
  NIQ_REFRESH_QC_GRAPH
} from '../actions/niq-qc-actions.js'

import {
  NIQ_SEARCH_TOGGLE_LOCK_DATA,
  NIQ_SEARCH_FILTER_CHANGED,
  NIQ_QC_DESCRIPTION_SAVE_EDITS,
  NIQ_QC_DESCRIPTION_SAVE_EDITS_STARTED,
  NIQ_DESCRIPTION_SAVE_EDITS_SUCCEEDED,
  NIQ_QC_DESCRIPTION_SAVE_EDITS_FAILED,
  NIQ_DESCRIPTION_EDIT_STATUS_UPDATED,
  NIQ_DESCRIPTION_EDIT_STATUS_UPDATE_FAILED,
  NIQ_REFRESH_QC_DESCRIPTION_EDIT_STATUS,
  NIQ_FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS,
  NIQ_FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED,
  NIQ_FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_FAILED
} from '../actions/niq-widget-actions'

import { buildAggregationRequest } from '../utils/niq-agg-request-helper'

import { NIQ_SEARCH_STORE_PATH, NIQ_SEARCH_EDIT_STORE_PATH, NIQ_SEARCH_QC_STORE_PATH } from '../reducers/niq-reducers'

const queryActionWhitelist = [
  NIQ_APPLY_QUERY,
  NIQ_SEARCH_FILTER_CHANGED,
  NIQ_FETCH_TYPE_CHILDREN_SUCCEEDED,
  NIQ_TOGGLE_QC_MODE,
  NIQ_SEARCH_TOGGLE_LOCK_DATA,
  NIQ_QC_TIME_INTERVAL_CHANGED,
  NIQ_CHANGE_CATEGORY_FILTER,
  NIQ_SET_ACTIVE_SEARCH_TAB
]

const { aggregations, aggGroups, esFields, itemTypes } = constants

const MAX_SELECTED_SIZE = 10000
const { QC_MODE } = searchToolTabs
const getSearch = state => state[NIQ_SEARCH_STORE_PATH]
const getSearchQC = state => state[NIQ_SEARCH_QC_STORE_PATH]
const getSearchEdit = state => state[NIQ_SEARCH_EDIT_STORE_PATH]
const isQCModeActive = searchState => searchState.isActiveTab === QC_MODE && !searchState.isLocked

function* getDrillDownByParams() {
  const searchQC = yield select(getSearchQC)
  return {
    splitBy: searchQC.drillDownBy ? searchQC.splitBy : null, // if no drillDownBy param is selected, don't pass any splitBy value
    drillDownBy: searchQC.drillDownBy
  }
}

const defaultDrillDownByAggs = [
  aggregations.FACTOR,
  aggregations.QUANTITY,
  aggregations.WEIGHT,
  aggregations.PRICE,
  aggregations.REVENUE,
  aggregations.UNITS,
  aggregations.USER_ID
]

let previousSplitBy = null
let previousDrillDownBy = null
let previousTimeInterval = 'month'

function* buildDescriptionQueryPayload(aggs, queryFilters) {
  const searchQC = yield select(getSearchQC)
  const aggGroup = searchQC[aggregations.DESCRIPTION_WITH_HISTORY]
  // agg params
  const aggregationParams = {}
  // search filters
  for (const aggKey of Object.keys(aggs)) {
    const aggParams = { ...aggGroup[aggKey], ...aggs[aggKey] }
    aggregationParams[aggKey] = buildAggregationRequest(aggKey, aggParams)
  }
  return {
    aggregationFields: aggregationParams,
    esTimeout: config.extendedTimeout,
    ...queryFilters
  }
}

// Map data for server call
function* buildQueryPayload(aggGroupKey, aggs, queryFilters) {
  const search = yield select(getSearch)
  const searchQC = yield select(getSearchQC)
  const aggGroup = searchQC[aggGroupKey]
  // agg params
  const aggregationParams = {}
  // search filters
  const filters = {}
  for (const aggKey of Object.keys(aggs)) {
    const aggParams = {
      ...aggGroup[aggKey],
      ...aggs[aggKey]
    }
    aggregationParams[aggKey] = buildAggregationRequest(aggKey, aggParams)
  }
  if (queryFilters) {
    // drildownby specified
    filters.terms = createCurrentPreviousFilters(queryFilters)
    if (queryFilters.drillDownBy && ['none', 'last_none'].indexOf(queryFilters.drillDownBy.type) === -1) {
      filters.terms = [...filters.terms, createDrillDownByFilter(queryFilters.drillDownBy)]
    }

    // for category filter
    if (search.searchFilters.categoryFilterList.length) {
      filters.terms = [
        ...filters.terms,
        createContextFilter(search.searchFilters.categoryFilterList, queryFilters.queryPrevious)
      ]
    }

    // if need to filter by any filter terms
    if (queryFilters.terms && queryFilters.terms.length) {
      filters.terms = [...filters.terms, ...queryFilters.terms]
    }

    // scoreCard overridden
    filters[ES_SCORECARD_KEY] = search.searchFilters[ES_SCORECARD_KEY]
  }
  return {
    aggregationFields: aggregationParams,
    filters,
    queryCurrent: queryFilters.queryCurrent,
    queryPrevious: queryFilters.queryPrevious,
    esTimeout: config.extendedTimeout
  }
}

function createCurrentPreviousFilters(params) {
  if (params.queryCurrent && !params.queryPrevious) {
    return [currentOnlyFilter()]
  } else if (params.queryPrevious && !params.queryCurrent) {
    return [previousOnlyFilter()]
  }
  return []
}

function createDrillDownByFilter(drillDownBy) {
  if (drillDownBy && drillDownBy.name && drillDownBy.type) {
    return {
      fieldName: esFields[drillDownBy.type],
      values: [drillDownBy.name]
    }
  }
  return {}
}

function createContextFilter(categoryFilterList, previous) {
  return {
    fieldName: previous ? esFields.last_category : esFields.category,
    values: categoryFilterList.map(filter => filter.value)
  }
}

function* shouldQuery(action) {
  const search = yield select(getSearch)
  const searchQC = yield select(getSearchQC)
  if (action.type !== 'SEARCH_FILTER_CHANGED') {
    yield put({
      type: NIQ_GET_SEARCH_METADATA
    })
  }
  if (
    !isQCModeActive(search) || // QC Tab is active
    !search.searchFilters.categoryFilterList ||
    !search.searchFilters.categoryFilterList.length // context filters selected
  ) {
    return
  }
  if (
    search.isStale[QC_MODE] ||
    (action.type === NIQ_SEARCH_FILTER_CHANGED && action.key === 'range') ||
    previousTimeInterval !== searchQC.timeInterval
  ) {
    yield queryAll()
    previousTimeInterval = searchQC.timeInterval
    previousDrillDownBy = searchQC.drillDownBy
  }
}

function* queryAll() {
  yield queryBCMValues(MAX_SELECTED_SIZE)
  const searchQC = yield select(getSearchQC)
  yield querySplitByChart(searchQC.splitBy, 8, searchQC.timeInterval)
}

function* querySplitByChart(splitBy = 'none', selectedSize = 8, selectedInterval = 'month', offset = 0) {
  const aggParams = { splitBy, selectedSize, selectedInterval }
  const searchQC = yield select(getSearchQC)
  let values = ['none']
  if (searchQC.splitBy !== 'none') {
    const data = searchQC.drillDownOptions[searchQC.splitBy].data
    offset = keys(searchQC.splitByChart.data.revenue).length
    values = [...data].slice(offset, selectedSize).map(item => item.name)
  }
  const promises = []
  values.forEach(value => {
    const lastQueryParams = getSplitByLastQueryParams(splitBy, value)
    const queryParams = getSplitByQueryParams(splitBy, value)
    promises.push(query(aggGroups.SPLIT_BY_CHART, { [aggregations.REVENUE]: { ...aggParams } }, queryParams))
    promises.push(
      query(
        aggGroups.SPLIT_BY_CHART,
        {
          [aggregations.LAST_REVENUE]: {
            ...aggParams,
            splitBy: aggParams.splitBy === 'none' ? 'none' : `last_${aggParams.splitBy}`
          }
        },
        lastQueryParams
      )
    )
  })
  //  send requests
  yield promises
}

function currentOnlyFilter() {
  return { fieldName: 'in_current', values: [true] }
}

function previousOnlyFilter() {
  return { fieldName: 'in_previous', values: [true] }
}

function getDrillDownQueryParam(drillDownBy) {
  return {
    drillDownBy: { ...drillDownBy },
    queryCurrent: true
  }
}

function getDrillDownLastQueryParam(drillDownBy) {
  const lastQueryParams = {
    queryOnlyPrevious: true,
    queryPrevious: true
  }
  if (drillDownBy && drillDownBy.type) {
    lastQueryParams.drillDownBy = {
      ...drillDownBy,
      type: `last_${drillDownBy.type}`
    }
  }
  return lastQueryParams
}

function getDrillDownLastAggsParams(aggParams) {
  const lastAggsParams = { ...aggParams }
  if (aggParams.splitBy !== 'none') {
    lastAggsParams.splitBy = aggParams.splitBy ? `last_${aggParams.splitBy}` : null
  }
  return lastAggsParams
}

function* queryDrillDownByChart(splitBy = 'none', drillDownBy = null, selectedInterval = 'month') {
  // build aggs
  const aggParams = {
    splitBy,
    drillDownBy,
    selectedInterval
  }
  const searchQC = yield select(getSearchQC)
  const queryParams = getDrillDownQueryParam(drillDownBy)
  const lastQueryParams = getDrillDownLastQueryParam(drillDownBy)
  const lastAggsParams = getDrillDownLastAggsParams(aggParams)
  const promises = []
  defaultDrillDownByAggs.forEach(aggType => {
    if (searchQC.drilldownEnabledStatus && searchQC.drilldownEnabledStatus[aggType]) {
      promises.push(
        query(
          constants.aggGroups.DRILL_DOWN_CHART,
          {
            [aggType]: { ...aggParams }
          },
          queryParams
        )
      )
      promises.push(
        query(
          constants.aggGroups.DRILL_DOWN_CHART,
          {
            [`last_${aggType}`]: lastAggsParams
          },
          lastQueryParams
        )
      )
    }
  })
  // send requests
  yield promises
}

function* queryBCMValues(selectedSize = 50) {
  const aggParams = { selectedSize }
  const queryParams = { queryPrevious: false, queryCurrent: true }
  yield query(
    aggGroups.DRILL_DOWN_OPTIONS,
    {
      [aggregations.BRAND]: { ...aggParams },
      [aggregations.CATEGORY]: { ...aggParams },
      [aggregations.MERCHANT]: { ...aggParams }
    },
    queryParams
  )
}

function* buildDescriptionQueryParam(type, onlyCount) {
  const filters = {}
  const search = yield select(getSearch)
  const searchQC = yield select(getSearchQC)
  filters.type = type
  filters.from = moment.utc(moment(searchQC.from).format(constants.dateFormat.qcTool)).valueOf()
  filters.to = moment.utc(moment(searchQC.to).format(constants.dateFormat.qcTool)).valueOf()
  filters.onlyCount = onlyCount

  if (searchQC.drillDownBy && searchQC.drillDownBy.name !== 'none') {
    filters.drilldown = {
      fieldName: esFields[searchQC.drillDownBy.type],
      value: searchQC.drillDownBy.name
    }
  }

  filters.context = createContextFilter(search.searchFilters.categoryFilterList)
  filters[ES_SCORECARD_KEY] = search.searchFilters[ES_SCORECARD_KEY]

  return { filters }
}

function* getAllCounts() {
  const descType = itemTypes
  const promises = []
  Object.values(descType).forEach(val => {
    promises.push(
      queryCount(aggGroups.DESCRIPTION_WITH_HISTORY, {
        type: val,
        onlyCount: true
      })
    )
  })
  // send requests
  yield promises
}

function* queryCount(aggGroup, queryFilters) {
  const apiPath = '/api/es/qc/descriptioncount'
  try {
    const descQueryParam = yield buildDescriptionQueryParam(queryFilters.type, queryFilters.onlyCount)
    yield put({
      type: NIQ_QC_DESCRIPTION_COUNT_STARTED,
      filterType: queryFilters.type
    })
    const queryResult = yield call(() => axios.post(apiPath, descQueryParam))
    yield put({
      type: NIQ_QC_DESCRIPTION_COUNT_SUCCEEDED,
      count: queryResult.data.itemCount,
      filterType: queryFilters.type
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: NIQ_QC_DESCRIPTION_COUNT_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: `An error occurred while fetching description count`,
          headline: `Description Count - ${queryFilters.type}`
        }
      ],
      alertTimeout: 10000,
      message: error.message,
      aggGroup,
      loading: false
    })
  }
}

function* getDescriptionItemTypeData() {
  const searchQC = yield select(getSearchQC)
  const stateParams = searchQC[aggGroups.DESCRIPTION_WITH_HISTORY]
  const selectedSize = stateParams.selectedSize
  const sortBy = stateParams.sortBy
  const sortOrder = stateParams.sortOrder
  const aggParams = { selectedSize, sortBy, sortOrder }
  const descQueryParam = yield buildDescriptionQueryParam(searchQC.descriptionItemType, false)
  yield query(
    aggGroups.DESCRIPTION_WITH_HISTORY,
    {
      [aggregations.DESCRIPTION_WITH_HISTORY]: { ...aggParams }
    },
    descQueryParam
  )
}

function* queryDescriptionsWithHistory() {
  const searchQC = yield select(getSearchQC)
  if (searchQC.isDescriptionEnable) {
    yield all([getAllCounts(), getDescriptionItemTypeData()])
  }
}

function* delayQueryDescriptionsWithHistory() {
  yield delay(2000)
  yield queryDescriptionsWithHistory()
}

function* postSuccessForAggQuery(aggType, data) {
  if (aggType === aggregations.DESCRIPTION_WITH_HISTORY) {
    const ids = map(data[aggregations.DESCRIPTION_WITH_HISTORY], 'dictionary_key')
    yield fetchDescriptionEditStatus(ids)
  }
}

function* query(aggGroup, aggs, queryFilters, aggKey) {
  const apiPath = '/api/es/query'
  let queryParams
  try {
    yield put({
      type: NIQ_QC_QUERY_RUN_STARTED,
      aggGroup,
      aggKey,
      loading: true,
      params: { ...aggs, ...queryFilters }
    })
    if (aggGroup === aggGroups.DESCRIPTION_WITH_HISTORY) {
      queryParams = yield buildDescriptionQueryPayload(aggs, queryFilters)
    } else {
      queryParams = yield buildQueryPayload(aggGroup, aggs, queryFilters)
    }
    const queryResult = yield call(() => axios.post(apiPath, queryParams, { timeout: config.extendedTimeout }))
    delete queryResult.data.timeout
    yield put({
      type: NIQ_QC_QUERY_RUN_SUCCEEDED,
      data: queryResult.data,
      aggGroup,
      params: { ...aggs, ...queryFilters },
      loading: false
    })
    yield postSuccessForAggQuery(aggGroup, queryResult.data)
  } catch (error) {
    window.captureException(error)
    // Notify user with the specific details of the query that failed
    const errorMessage = createQueryErrorMessage(aggGroup, aggs, queryParams)
    yield put({
      type: NIQ_QC_QUERY_RUN_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: errorMessage,
          headline: `QC ${aggGroup}`
        }
      ],
      alertTimeout: 10000,
      message: error.message,
      aggGroup,
      params: { ...aggs, ...queryParams },
      loading: false
    })
  }
}

function createQueryErrorMessage(aggGroup, aggs, queryParams) {
  let errorMessage
  if (aggGroup === 'splitByChart' || aggGroup === 'drillDownChart') {
    let queryKey
    if (aggGroup === 'drillDownChart') {
      queryKey = keys(aggs)[0]
    } else if (aggGroup === 'splitByChart') {
      queryKey =
        get(aggs, [keys(aggs)[0], 'splitBy']) === 'none'
          ? keys(aggs)[0]
          : get(queryParams.filters.terms, [queryParams.filters.terms.length - 1, 'values', 0])
    }
    errorMessage = `An error occurred while fetching data for ${queryKey} of type ${aggGroup}`
  } else {
    errorMessage = `An error occurred while fetching data for ${aggGroup}`
  }
  return errorMessage
}

// TODO: find a better way to do this

function* drillDownByUpdated(action) {
  const search = yield select(getSearch)
  if (!isQCModeActive(search)) {
    return
  }
  const searchQC = yield select(getSearchQC)
  const { splitBy, drillDownBy } = yield getDrillDownByParams()
  const responses = []
  if (previousSplitBy !== splitBy || previousDrillDownBy !== drillDownBy) {
    responses.push(
      queryDrillDownByChart(
        searchQC.drillDownBy ? searchQC.splitBy : 'null',
        searchQC.drillDownBy,
        searchQC.timeInterval
      )
    )
    previousSplitBy = splitBy
    previousDrillDownBy = drillDownBy
  }
  yield responses
}

function* showMoreSplitByCharts(action) {
  const searchQC = yield select(getSearchQC)
  let count = 8
  // TODO - This will be replaced by actual functionality
  if (searchQC.splitByChart.data) {
    count = Object.keys(searchQC.splitByChart.data.revenue).length + 8
  }
  yield querySplitByChart(searchQC.splitBy, count, searchQC.timeInterval)
}

function* splitByUpdated(action) {
  const search = yield select(getSearch)
  if (!isQCModeActive(search)) {
    return
  }
  const searchQC = yield select(getSearchQC)
  yield querySplitByChart(searchQC.splitBy, searchQC.splitByChart.selectedSize, searchQC.timeInterval)
}

function* descriptionItemTypeUpdated() {
  const search = yield select(getSearch)
  if (!isQCModeActive(search)) {
    return
  }
  yield getDescriptionItemTypeData()
}

function* batchSaveDictionary(stagedEdits, descriptions) {
  const search = yield select(getSearch)
  const selectedIndex = get(search, 'searchFilters.' + ES_SCORECARD_KEY)

  const edits = []
  const updateKeys = []

  _(descriptions)
    .keyBy('dictionary_key')
    .at(keys(stagedEdits))
    .value()
    .forEach(entry => {
      if (entry && entry.dictionary_key) {
        edits.push({
          ...entry,
          selectedIndex,
          edits: stagedEdits[entry.dictionary_key]
        })
        updateKeys.push(entry.dictionary_key)
      }
    })

  const batches = chunk(edits, 100)
  yield batches.map(batch =>
    call(() =>
      axios.post('/api/niq/dictionary/update', {
        rows: batch
      })
    )
  )
  return updateKeys
}

function* saveQCDictionaryEntries() {
  const searchEdit = yield select(getSearchEdit)
  const searchQC = yield select(getSearchQC)
  try {
    yield put({
      type: NIQ_QC_DESCRIPTION_SAVE_EDITS_STARTED,
      alerts: [
        {
          id: cuid(),
          type: 'info',
          message: 'Submitting changes...',
          headline: 'Dictionary Edits'
        }
      ]
    })
    const removeKeys = yield batchSaveDictionary(
      searchEdit.description.stagedEdits,
      searchQC.descriptionWithHistory.data
    )
    yield put({
      type: NIQ_DESCRIPTION_SAVE_EDITS_SUCCEEDED,
      removeKeys,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: 'All changes were successfully submitted.',
          headline: 'Dictionary Edits'
        }
      ]
    })
  } catch (error) {
    window.captureException(error)
    // TODO: handle timeouts and other errors
    yield put({
      type: NIQ_QC_DESCRIPTION_SAVE_EDITS_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: 'An error occurred while creating edit jobs.',
          headline: 'Dictionary Edits'
        }
      ],
      alertTimeout: 10000,
      message: error.message
    })
  } finally {
    // Refresh description edits after dictionary update
    const ids = map(searchQC.descriptionWithHistory.data, 'dictionary_key')
    yield fetchDescriptionEditStatus(ids)
  }
}

function processDictUpdateData(data) {
  const groupedById = groupBy(data, 'id')
  return mapValues(groupedById, arr => {
    return keyBy(arr, 'attributeEdited')
  })
}

function* fetchDescriptionEditStatus(ids) {
  try {
    if (!ids || !ids.length) {
      return
    }
    const batches = chunk(ids, 500)
    for (const batch of batches) {
      const result = yield call(() =>
        axios.post('/api/niq/dictionary/entries/search', {
          ids: batch
        })
      )
      if (result.status === 200) {
        yield put({
          type: NIQ_DESCRIPTION_EDIT_STATUS_UPDATED,
          data: processDictUpdateData(result.data)
        })
      } else {
        yield put({
          type: NIQ_DESCRIPTION_EDIT_STATUS_UPDATE_FAILED,
          alerts: [
            {
              id: cuid(),
              type: 'warning',
              message: 'There was an error while fetching edit status for QC descriptions',
              headline: 'Edit Items'
            }
          ],
          message: 'Backend Error'
        })
      }
    }
  } catch (error) {
    window.captureException(error)
    yield put({
      type: NIQ_DESCRIPTION_EDIT_STATUS_UPDATE_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'warning',
          message: 'There was an error while fetching edit status for QC descriptions',
          headline: 'Edit Items'
        }
      ],
      message: error.message
    })
  }
}

function* fetchAllDescriptionEditStatus() {
  const searchQC = yield select(getSearchQC)
  yield fetchDescriptionEditStatus(map(searchQC.descriptionWithHistory.data, 'dictionary_key'))
}

function* refreshDrillDownGraph(payload) {
  const searchQC = yield select(getSearchQC)
  const aggType = payload.chartType === 'item_count' ? 'revenue' : payload.chartType
  if (searchQC.drilldownEnabledStatus && !searchQC.drilldownEnabledStatus[payload.chartType]) {
    return
  }
  const { drillDownBy } = yield getDrillDownByParams()
  const aggParams = {
    splitBy: searchQC.drillDownBy ? searchQC.splitBy : 'none',
    drillDownBy: searchQC.drillDownBy,
    selectedInterval: searchQC.timeInterval
  }
  if (payload.type === 'previous') {
    const lastQueryParams = getDrillDownLastQueryParam(drillDownBy)
    const lastAggsParams = getDrillDownLastAggsParams(aggParams)
    yield query(
      constants.aggGroups.DRILL_DOWN_CHART,
      {
        [`last_${aggType}`]: lastAggsParams
      },
      lastQueryParams
    )
  } else if (payload.type === 'current') {
    const queryParams = getDrillDownQueryParam(drillDownBy)
    yield query(
      constants.aggGroups.DRILL_DOWN_CHART,
      {
        [aggType]: { ...aggParams }
      },
      queryParams
    )
  }
}

function getSplitByQueryParams(splitBy, value) {
  const queryParams = { queryCurrent: true }
  if (splitBy !== 'none') {
    queryParams.terms = [{ fieldName: esFields[splitBy], values: [value] }]
  }
  return queryParams
}

function getSplitByLastQueryParams(splitBy, value) {
  const lastQueryParams = { queryPrevious: true }
  if (splitBy !== 'none') {
    lastQueryParams.terms = [{ fieldName: esFields[`last_${splitBy}`], values: [value] }]
  }
  return lastQueryParams
}

function* refreshSplitGraph(payload) {
  const searchQC = yield select(getSearchQC)

  const aggParams = {
    splitBy: searchQC.splitBy,
    selectedInterval: searchQC.timeInterval
  }

  let value = 'none'
  if (payload.type === 'previous') {
    if (searchQC.splitBy !== 'none') {
      value = payload.chartType
    }
    const lastQueryParams = getSplitByLastQueryParams(aggParams.splitBy, value)
    yield query(
      constants.aggGroups.SPLIT_BY_CHART,
      {
        [constants.aggregations.LAST_REVENUE]: {
          ...aggParams,
          splitBy: aggParams.splitBy === 'none' ? 'none' : `last_${aggParams.splitBy}`
        }
      },
      lastQueryParams,
      value
    )
  } else if (payload.type === 'current') {
    if (searchQC.splitBy !== 'none') {
      value = payload.chartType
    }
    const queryParams = getSplitByQueryParams(aggParams.splitBy, value)
    yield query(
      constants.aggGroups.SPLIT_BY_CHART,
      { [constants.aggregations.REVENUE]: { ...aggParams } },
      queryParams,
      value
    )
  }
}

function* refreshQCGraphs(action) {
  if (action.payload && action.payload.componentType === 'drilldown') {
    yield refreshDrillDownGraph(action.payload)
  } else if (action.payload && action.payload.componentType === 'split') {
    yield refreshSplitGraph(action.payload)
  }
}

function* fetchQCDescriptionEditSuggestions() {
  const searchEdit = yield select(getSearchEdit)
  const payload = []
  // rows property will always present
  searchEdit.description.edits.rows.forEach(row => {
    if (row.isBrandEditable) {
      payload.push({
        description: row.description,
        merchantId: row.merchantId,
        categoryId: row.category_id,
        descriptionId: row.id
      })
    }
  })

  try {
    const CHUNK_SIZE = 50
    const batches = chunk(payload, CHUNK_SIZE)
    for (const batch of batches) {
      const autoSuggestResult = yield call(() => axios.post('/api/dictionary/edits/suggestions', batch))
      if (autoSuggestResult && autoSuggestResult.data) {
        yield put({
          type: NIQ_FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED,
          data: autoSuggestResult.data,
          loading: true
        })
      }
    }
    yield put({
      type: NIQ_FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED,
      loading: false
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: NIQ_FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: `Failed to get brand suggestions for some descriptions`,
          headline: 'Description Edit'
        }
      ],
      alertTimeout: 10000,
      data: error.message
    })
  }
}

function* qcSaga() {
  yield takeLatest(action => queryActionWhitelist.includes(action.type), shouldQuery)
  yield takeLatest(NIQ_QC_SPLIT_BY_CHANGED, splitByUpdated)
  yield takeLatest(NIQ_QC_DESCRIPTION_SAVE_EDITS, saveQCDictionaryEntries)
  yield takeLatest(NIQ_QC_SHOW_MORE_SPLITBY_CHARTS, showMoreSplitByCharts)
  yield takeLatest(NIQ_REFRESH_QC_DESCRIPTION_EDIT_STATUS, fetchAllDescriptionEditStatus)
  yield takeEvery(NIQ_QC_DRILLDOWN_BY_CHANGED, drillDownByUpdated)
  yield takeEvery(NIQ_CHANGE_DESCRIPTION_ITEM_TYPE, descriptionItemTypeUpdated)
  yield takeLatest(NIQ_QC_REFRESH_DESCRIPTION_DATA, queryDescriptionsWithHistory)
  yield takeLatest(NIQ_QC_SELECTED_TIME_RANGE_CHANGED, delayQueryDescriptionsWithHistory)
  yield takeLatest(NIQ_REFRESH_QC_GRAPH, refreshQCGraphs)
  yield takeLatest(NIQ_FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS, fetchQCDescriptionEditSuggestions)
}

export default qcSaga

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { addCustomModal } from '../../actions/modal-actions'

import { Glyphicon, Button } from 'react-bootstrap'
import DocumentTitle from 'react-document-title'
import Loader from '../Loader'
import Folder from './Folder'
import File from './File'
import DnDWrapper from './DnDWrapper'
import BetaAlert from '../BetaAlert'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import UserService from '../../services/user-service'
import { appName, products } from '../../constants/constants'
import Unauthorized from '../Unauthorized'

import {
  fetchFolder,
  moveFolder,
  moveReport,
  renameFolder,
  renameReport,
  createNewFolder,
  deleteFolder,
  deleteReport
} from '../../actions/report-folder-actions'
import {
  folderPageKeyMap,
  isFolderItemFocused,
  focusFirstItem,
  findFolderItemDetails,
  findNextItem,
  findPreviousItem,
  keyActions,
  KeyMapInfo,
  focusNextFolderItem,
  focusPrevFolderItem
} from './folder-utils'

import { HotKeys } from 'react-hotkeys'
import cx from 'classnames'
import { get, isEmpty } from 'lodash'
import './index.scss'

class ReportsFolderPage extends Component {
  constructor(props) {
    super(props)
    this.keyHandlers = {
      [keyActions.NEXT_FOLDER_ITEM]: focusNextFolderItem,
      [keyActions.PREV_FOLDER_ITEM]: focusPrevFolderItem,
      [keyActions.DELETE_FOLDER_ITEM]: this.deleteFolderItem,
      [keyActions.PARENT_FOLDER]: this.openParentFolder
    }
  }

  componentDidMount() {
    const { folderId, fetchFolder } = this.props
    fetchFolder(folderId)
  }

  componentDidUpdate(prevProps) {
    const { folderId, fetchFolder, loading, subfolders, reports } = this.props
    if (prevProps.folderId !== folderId && !loading) {
      fetchFolder(folderId)
    }
    if ((prevProps.subfolders !== subfolders || prevProps.reports !== reports) && !isFolderItemFocused()) {
      focusFirstItem() // if no items are focused, focus on first folder item
    }
  }

  isUnauthorized = () => {
    return !get(this.props.userProducts, [products.adminToolsCustomReportManager, 'enabled'])
  }

  routeToFolder = folderId => {
    this.props.history.push(`/admin/report/folders/${folderId}`)
  }

  routeToReport = (reportId = 'new') => {
    this.props.history.push(`/admin/reports/edit/${reportId}`)
  }

  itemDroppedOnFolder = (dropFolder, item = '') => {
    const { moveFolder, moveReport } = this.props
    const [itemType, itemId] = item.split('-')
    const [, dropFolderId] = dropFolder.split('-')
    if (itemType === 'file') {
      // report dropped
      moveReport(itemId, dropFolderId)
    } else if (itemType === 'folder' && dropFolderId !== Number(itemId)) {
      // folder dropped
      moveFolder(itemId, dropFolderId)
    }
  }

  showKeyboardShortcuts = () => {
    this.props.addCustomModal({
      modalType: 'info',
      id: 'folder-keyboard-shortcuts-modal',
      title: 'Keyboard Shortcuts',
      content: <KeyMapInfo />
    })
  }

  createNewFolder = () => {
    const folderName = prompt('Enter a name for the New folder', '')
    const { folderId, createNewFolder } = this.props
    if (folderName) {
      createNewFolder(folderId, folderName)
    }
  }

  openParentFolder = () => {
    const { currFolder: { id, parentId } = {} } = this.props
    if (id !== parentId) {
      this.routeToFolder(parentId)
    }
  }

  deleteFolderItem = event => {
    const { target: folderItem } = event
    const { deleteFolder, deleteReport, isPortalAdmin } = this.props
    const { itemType, itemId } = findFolderItemDetails(folderItem)
    if (!itemId) {
      return
    }
    // creata on delete success
    const nextItem = findNextItem(folderItem)
    const prevItem = findPreviousItem(folderItem)
    const onDeleteSuccess = () => {
      if (nextItem) {
        nextItem.focus()
      } else if (prevItem) {
        prevItem.focus()
      }
    }

    if (itemType === 'folder') {
      // deleting folder
      if (!isPortalAdmin) {
        window.alert('Only Portal Admins can Delete a Folder!')
        return
      }
      const confirmation = window.confirm(
        'Are you sure you want to Delete this Folder ?\nIf there are any Reports inside it, they will be moved to Home Folder'
      )
      if (!confirmation) return
      deleteFolder(itemId, onDeleteSuccess)
    } else {
      // deleting report
      const confirmation = window.confirm('Are you sure you want to Delete this Report ?')
      if (!confirmation) return
      deleteReport(itemId, onDeleteSuccess)
    }
  }

  renderFullpath(folder) {
    const crumbs = []
    let parent = folder
    // method to create folder link
    const createCrumb = subfolder => {
      const { name, id } = subfolder
      const isRoot = id === 0
      const isCurrFolder = id === folder.id
      return {
        id,
        name: isRoot ? 'Home' : name,
        glyph: isRoot ? 'home' : isCurrFolder ? 'folder-open' : 'folder-close',
        isLink: !isCurrFolder
      }
    }
    if (folder.id !== 0) {
      while (parent) {
        crumbs.unshift(createCrumb(parent))
        parent = parent.parent
      }
    }
    return (
      <ul className="breadcrumb folder-breadcrumb">
        {crumbs.map(({ name, glyph, id, isLink }) => {
          return (
            <Folder
              key={id}
              id={id}
              name={name}
              Tag={'li'}
              glyph={glyph}
              tabIndex={isLink ? 0 : undefined}
              className={cx({ link: isLink })}
              onClick={() => this.routeToFolder(id)}
              droppable={isLink}
              onItemDragOver={isLink ? () => this.routeToFolder(id, true) : undefined}
              onItemDropped={this.itemDroppedOnFolder}
            />
          )
        })}
      </ul>
    )
  }

  renderSubFolder = ({ name, id }) => {
    const itemKey = `folder-${id}`
    return (
      <li key={itemKey} className="folder-item" id={itemKey}>
        <Folder
          id={id}
          name={name}
          onDoubleClick={() => this.routeToFolder(id)}
          onItemDragOver={() => this.routeToFolder(id)}
          onItemDropped={this.itemDroppedOnFolder}
          onRename={this.props.renameFolder}
          draggable
        />
      </li>
    )
  }

  renderReport = ({ name, id }) => {
    const itemKey = `file-${id}`
    return (
      <li key={id} className="folder-item" id={itemKey}>
        <File
          id={id}
          name={name}
          className="report-item"
          onDoubleClick={() => this.routeToReport(id)}
          onRename={this.props.renameReport}
          draggable
        />
      </li>
    )
  }

  renderFolderItems() {
    const { subfolders = [], reports = [] } = this.props
    return (
      <ul className="sub-folders">
        {subfolders.map(this.renderSubFolder)}
        {reports.map(this.renderReport)}
        {subfolders.length + reports.length === 0 && <div className="no-items"> Folder is empty </div>}
      </ul>
    )
  }

  renderSubFolderHeader() {
    const { isPortalAdmin } = this.props
    return (
      <div className="sub-folder-header">
        <div className="folder-actions">
          <Button className="btn_link btn-link" onClick={this.showKeyboardShortcuts}>
            <Glyphicon glyph="flash" /> Shortcuts
          </Button>
          {isPortalAdmin && (
            <Button className="btn_link btn-link" onClick={this.createNewFolder}>
              <Glyphicon glyph="plus" /> New Folder
            </Button>
          )}
          <Button className="btn_link btn-link" onClick={() => this.routeToReport('new')}>
            <Glyphicon glyph="plus" /> New Report
          </Button>
        </div>
      </div>
    )
  }

  renderFolderTitle() {
    const { currFolder: { id, name, parentId } = {} } = this.props
    if (id === 0) {
      return (
        <h2>
          <Glyphicon glyph="home" />
          Home
        </h2>
      )
    }
    return (
      <h2>
        <Folder
          id={parentId}
          name={name}
          className="link"
          onClick={() => this.routeToFolder(parentId)}
          onItemDragOver={() => this.routeToFolder(parentId)}
          onItemDropped={this.itemDroppedOnFolder}
          glyph={'level-up'}
        />
      </h2>
    )
  }

  getDocumentTitle = () => {
    const { documentTitle, currFolder = {} } = this.props
    let { name } = currFolder
    let title = appName
    if (documentTitle && name) {
      if (name === 'root') {
        name = 'Home'
      }
      title = `${name} | ${documentTitle}`
    }
    return title
  }

  render() {
    if (this.isUnauthorized()) {
      return <Unauthorized />
    }
    const { loading, currFolder, folderId } = this.props
    if (folderId == null || isEmpty(currFolder)) {
      return null
    }
    return (
      <DocumentTitle title={this.getDocumentTitle()}>
        <div className="report-folders-page">
          <BetaAlert
            enabled
            message={`
              Welcome to the Reports Folder page.
              You can use the buttons on the top right to create a new folder or report at your current location.
              Click on a folder to select it. Double click it to expand it.
              You can also drag folders in the area below.
              Note: This feature is still a work in progress.
              `}
          />
          {this.renderFullpath(currFolder)}
          <DnDWrapper
            id={`folder-${folderId}`}
            className="folder-details"
            droppable
            onDropReceived={this.itemDroppedOnFolder}
            dragOverDelay={2000}
          >
            <div className="drop-message"> DROP HERE </div>
            {loading && <Loader overlap />}

            <HotKeys keyMap={folderPageKeyMap} handlers={this.keyHandlers}>
              <div className="sub-folders-details" ref={el => (this.folderItemsList = el)}>
                {this.renderSubFolderHeader()}
                {this.renderFolderTitle()}
                {this.renderFolderItems()}
              </div>
            </HotKeys>
          </DnDWrapper>
        </div>
      </DocumentTitle>
    )
  }
}

ReportsFolderPage.propTypes = {
  loading: PropTypes.bool,
  documentTitle: PropTypes.string,
  user: PropTypes.object,
  isPortalAdmin: PropTypes.bool,
  userProducts: PropTypes.object,
  folderId: PropTypes.number,
  currFolder: PropTypes.object,
  subfolders: PropTypes.array,
  reports: PropTypes.array,
  fetchFolder: PropTypes.func,
  moveFolder: PropTypes.func,
  moveReport: PropTypes.func,
  renameFolder: PropTypes.func,
  renameReport: PropTypes.func,
  createNewFolder: PropTypes.func,
  deleteFolder: PropTypes.func,
  deleteReport: PropTypes.func,
  addCustomModal: PropTypes.func,
  history: PropTypes.object
}

ReportsFolderPage.defaultProps = {
  documentTitle: 'Reports Manager',
  currFolder: {},
  subfolders: [],
  reports: []
}

function mapStateToProps({ session, reportFolders }, props) {
  const { currFolder, subfolders, reports } = reportFolders
  return {
    user: session.user,
    isPortalAdmin: UserService.isPortalAdmin(session.user),
    userProducts: UserService.getProducts(session.user),
    folderId: Number(get(props, ['match', 'params', 'folderId'])),
    currFolder,
    subfolders,
    reports
  }
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, {
    fetchFolder,
    moveReport,
    moveFolder,
    renameFolder,
    renameReport,
    createNewFolder,
    deleteFolder,
    deleteReport,
    addCustomModal
  })(ReportsFolderPage),
  '"Admin Reports Folder" page'
)

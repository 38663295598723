// @flow
import { merge, isEmpty, filter, omit } from 'lodash'

import {
  NIQ_QUERY_RUN_STARTED,
  NIQ_QUERY_RUN_SUCCEEDED,
  NIQ_QUERY_RUN_FAILED,
  NIQ_QUERY_SET_QUALITY,
  NIQ_QUERY_STATS_STARTED,
  NIQ_QUERY_STATS_SUCCEEDED,
  NIQ_QUERY_STATS_FAILED,
  NIQ_STAT_FILTER_CHANGED
} from '../actions/niq-search-actions'

import {
  NIQ_SORT_TABLE,
  NIQ_UPDATE_AGGREGATION,
  NIQ_TIME_AGGREGATION_CHANGED,
  NIQ_TIME_DIMENSION_CHANGED,
  NIQ_VIEW_CHANGED,
  NIQ_DIMENSION_CHANGED,
  NIQ_DESCRIPTION_TOGGLE_EDIT,
  NIQ_DESCRIPTION_TAG_FOR_EDIT,
  NIQ_DESCRIPTION_SAVE_EDITS_STARTED,
  NIQ_DESCRIPTION_SAVE_EDITS_SUCCEEDED,
  NIQ_DESCRIPTION_SAVE_EDITS_FAILED,
  NIQ_QC_DESCRIPTION_SAVE_EDITS_FAILED,
  NIQ_QC_DESCRIPTION_SAVE_EDITS_STARTED,
  NIQ_DESCRIPTION_CATEGORY_SUGGEST,
  NIQ_DESCRIPTION_BRAND_SUGGEST,
  NIQ_DESCRIPTION_SUGGEST_CLEAN,
  NIQ_DESCRIPTION_BRAND_SUGGEST_SUCCEEDED,
  NIQ_DESCRIPTION_CATEGORY_SUGGEST_SUCCEEDED,
  NIQ_DESCRIPTION_BRAND_EDIT_SELECT,
  NIQ_CHANGE_TOOLBAR_OPTION,
  NIQ_DESCRIPTION_CATEGORY_EDIT_SELECT,
  NIQ_START_LOADING_WHEEL,
  NIQ_DESCRIPTION_EDIT_STATUS_UPDATED,
  NIQ_CHANGE_SEARCH_COLUMNS_VISIBILITY,
  NIQ_SEARCH_DESCRIPTION_SELECTION_CHANGED,
  NIQ_BULK_STAGE_DICT_EDITS,
  NIQ_BULK_UNSTAGE_DICT_EDITS,
  NIQ_STAGE_DICT_EDIT,
  NIQ_REMOVE_STAGED_EDIT,
  NIQ_APPLY_TEMP_FILTER,
  NIQ_REMOVE_TEMP_FILTER,
  NIQ_WIDGETS_OPTIONS_CHANGED,
  NIQ_FETCH_DESCRIPTION_EDIT_SUGGESTIONS,
  NIQ_FETCH_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED,
  NIQ_FETCH_DESCRIPTION_EDIT_SUGGESTIONS_FAILED,
  NIQ_FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED
} from '../actions/niq-widget-actions'

import { LOGOUT_SUCCESS } from 'actions/auth-actions'
import { searchFields } from '../constants/NIQDescriptionTableConfig'
import constants from '../constants/niq-constants'
import updateDescription from 'utils/qc-tool-description'

const defaultEdits = {
  inEdit: false,
  trackedEdits: {},
  stagedEdits: {},
  tempFilters: {},
  edits: {
    rows: [],
    loading: false,
    brandSuggestions: [],
    categorySuggestions: [],
    brandId: null,
    brandName: null,
    categoryId: null,
    categoryFullPath: null
  }
}

const defaultVisibleColumns = filter(searchFields, 'visible', 'false').map(field => field.value)
const initialSearchEditState = {
  [constants.aggregations.DESCRIPTION]: {
    ...defaultEdits,
    data: [],
    loading: false,
    showToolbar: false,
    selectedSize: 200,
    sortBy: 'sales',
    sortOrder: 'desc',
    columns: defaultVisibleColumns
  },
  [constants.aggregations.BRAND]: {
    data: [],
    loading: false,
    selectedSize: 10,
    selectedView: 'table',
    selectedField: 'count',
    sortBy: '_count',
    sortOrder: 'desc',
    showToolbar: false
  },
  [constants.aggregations.MERCHANT]: {
    data: [],
    loading: false,
    selectedSize: 10,
    selectedView: 'table',
    selectedField: 'count',
    sortBy: '_count',
    sortOrder: 'desc',
    showToolbar: false
  },
  [constants.aggregations.CATEGORY]: {
    data: [],
    loading: false,
    selectedSize: 10,
    selectedView: 'table',
    selectedField: 'count',
    sortBy: '_count',
    sortOrder: 'desc',
    showToolbar: false
  },
  [constants.aggregations.SLICE_CATEGORY]: {
    data: [],
    loading: false,
    selectedSize: 10,
    selectedView: 'table',
    selectedField: 'count',
    sortBy: '_count',
    sortOrder: 'desc',
    showToolbar: false
  },
  [constants.aggregations.SLICE_BRAND]: {
    data: [],
    loading: false,
    selectedSize: 10,
    selectedView: 'table',
    selectedField: 'count',
    sortBy: '_count',
    sortOrder: 'desc',
    showToolbar: false
  },
  [constants.aggregations.INVALID_CATEGORY]: {
    data: [],
    loading: false,
    selectedSize: 10,
    selectedView: 'table',
    selectedField: 'count',
    sortBy: '_count',
    sortOrder: 'desc',
    showToolbar: false
  },
  [constants.aggregations.INVALID_BRAND]: {
    data: [],
    loading: false,
    selectedSize: 10,
    selectedView: 'table',
    selectedField: 'count',
    sortBy: '_count',
    sortOrder: 'desc',
    showToolbar: false
  },
  [constants.aggregations.OMNISALES_MODULE]: {
    data: [],
    loading: false,
    selectedSize: 10,
    selectedView: 'table',
    selectedField: 'count',
    sortBy: '_count',
    sortOrder: 'desc',
    showToolbar: false
  },
  [constants.aggregations.DESCRIPTION_FIRST_WORD]: {
    data: [],
    loading: false,
    selectedSize: 10,
    selectedView: 'table',
    selectedField: 'count',
    sortBy: '_count',
    sortOrder: 'desc',
    showToolbar: false
  },
  [constants.aggregations.TIME]: {
    data: {},
    loading: false,
    selectedField: 'revenue',
    selectedFilter: 'merchant_name',
    selectedInterval: 'month'
  },
  [constants.aggregations.DICTIONARY_COVERAGE]: {
    data: {},
    loading: false
  },
  stats: { selectedFilter: 'count' }
}

const handleStatsQuery = (action, state) => {
  let updatedStatProp = {}
  if (action.type === 'QUERY_STATS_SUCCEEDED') {
    if (action.statType === 'descriptionStat') {
      updatedStatProp = {
        count: action.data[action.aggType].value,
        loading: action.loading
      }
    } else {
      updatedStatProp = {
        count: action.data[action.aggType].count,
        revenue: action.data[action.aggType].value,
        loading: action.loading
      }
    }
  } else {
    if (action.statType === 'descriptionStat') {
      updatedStatProp = {
        count: 0,
        loading: action.loading
      }
    } else {
      updatedStatProp = {
        count: 0,
        revenue: 0,
        loading: action.loading
      }
    }
  }
  return {
    ...state,
    stats: {
      ...state.stats,
      [action.aggType]: {
        ...updatedStatProp
      }
    }
  }
}

const searchEditReducer = (state = initialSearchEditState, action) => {
  switch (action.type) {
    // start loading
    case NIQ_DESCRIPTION_CATEGORY_SUGGEST:
    case NIQ_DESCRIPTION_BRAND_SUGGEST:
      return {
        ...state,
        description: {
          ...state.description,
          edits: {
            ...state.description.edits,
            loading: true
          }
        }
      }

    case NIQ_DESCRIPTION_SUGGEST_CLEAN:
      return {
        ...state,
        description: {
          ...state.description,
          edits: {
            ...state.description.edits,
            brandSuggestions: [],
            categorySuggestions: []
          }
        }
      }

    case NIQ_DESCRIPTION_BRAND_SUGGEST_SUCCEEDED:
      return {
        ...state,
        description: {
          ...state.description,
          edits: {
            ...state.description.edits,
            loading: false,
            brandSuggestions: action.data
          }
        }
      }

    case NIQ_DESCRIPTION_CATEGORY_SUGGEST_SUCCEEDED:
      return {
        ...state,
        description: {
          ...state.description,
          edits: {
            ...state.description.edits,
            loading: false,
            categorySuggestions: action.data
          }
        }
      }

    case NIQ_BULK_STAGE_DICT_EDITS:
      const { rows, brandId, brandName, categoryId, categoryFullPath } = state.description.edits
      const stagedEdits = { ...state.description.stagedEdits } || {}
      if (brandId) {
        rows.forEach(row => {
          if (row.isBrandEditable) {
            stagedEdits[row.id] = stagedEdits[row.id] || {}
            stagedEdits[row.id].brand = {
              id: brandId,
              name: brandName
            }
          }
        })
      }
      if (categoryId) {
        rows.forEach(row => {
          if (row.isCategoryEditable) {
            stagedEdits[row.id] = stagedEdits[row.id] || {}
            stagedEdits[row.id].category = {
              id: categoryId,
              name: categoryFullPath
            }
          }
        })
      }
      if (!brandId && !categoryId) {
        rows.forEach(row => {
          stagedEdits[row.id] = stagedEdits[row.id] || {}
          if (row.isBrandEditable) {
            stagedEdits[row.id].brand = {
              id: row.brand_id,
              name: row.brand_full_path
            }
          }
          if (row.isCategoryEditable) {
            stagedEdits[row.id].category = {
              id: row.category_id,
              name: row.category_full_path
            }
          }
        })
      }
      return {
        ...state,
        description: {
          ...state.description,
          ...defaultEdits,
          stagedEdits: { ...stagedEdits },
          trackedEdits: state.description.trackedEdits
        }
      }
    case NIQ_BULK_UNSTAGE_DICT_EDITS:
      return {
        ...state,
        description: {
          ...state.description,
          stagedEdits: {}
        }
      }
    case NIQ_DESCRIPTION_BRAND_EDIT_SELECT:
      return {
        ...state,
        description: {
          ...state.description,
          edits: {
            ...state.description.edits,
            brandId: action.data ? action.data.id : null,
            brandName: action.data ? action.data.value : null
          }
        }
      }

    case NIQ_DESCRIPTION_CATEGORY_EDIT_SELECT:
      return {
        ...state,
        description: {
          ...state.description,
          edits: {
            ...state.description.edits,
            categoryId: action.data ? action.data.id : null,
            categoryFullPath: action.data ? action.data.value : null
          }
        }
      }
    case NIQ_DESCRIPTION_SAVE_EDITS_STARTED:
    case NIQ_QC_DESCRIPTION_SAVE_EDITS_STARTED:
      return {
        ...state,
        description: {
          ...state.description,
          loading: true
        }
      }
    case NIQ_QC_DESCRIPTION_SAVE_EDITS_FAILED:
    case NIQ_DESCRIPTION_SAVE_EDITS_FAILED:
      return {
        ...state,
        description: {
          ...state.description,
          loading: false
        }
      }
    case NIQ_DESCRIPTION_SAVE_EDITS_SUCCEEDED:
      let newStagedEdits = { ...state.description.stagedEdits }
      if (action.removeKeys.length) {
        newStagedEdits = omit(newStagedEdits, action.removeKeys)
      }
      return {
        ...state,
        description: {
          ...state.description,
          ...defaultEdits,
          stagedEdits: newStagedEdits,
          tempFilters: { ...state.description.tempFilters },
          trackedEdits: { ...state.description.trackedEdits },
          loading: false
        }
      }

    case NIQ_DESCRIPTION_TAG_FOR_EDIT:
      return {
        ...state,
        description: {
          ...state.description,
          edits: {
            ...state.description.edits,
            rows: action.data.add
              ? state.description.edits.rows.concat(action.data.rows)
              : state.description.edits.rows.filter(
                  row => action.data.rows.findIndex(dataRow => dataRow.id === row.id) === -1
                )
          }
        }
      }

    case NIQ_DESCRIPTION_TOGGLE_EDIT:
      let edits = {
        ...state.description.edits
      }
      if (state.description.inEdit) {
        edits = {
          ...edits,
          rows: [],
          loading: false,
          brandSuggestions: [],
          categorySuggestions: [],
          brandId: null,
          brandName: null,
          categoryId: null,
          categoryFullPath: null
        }
      }
      return {
        ...state,
        description: {
          ...state.description,
          inEdit: !state.description.inEdit,
          edits
        }
      }

    case NIQ_STAGE_DICT_EDIT:
      return {
        ...state,
        description: {
          ...state.description,
          stagedEdits: {
            ...state.description.stagedEdits,
            [action.key]: {
              ...state.description.stagedEdits[action.key],
              [action.attribute]: {
                id: action.id,
                name: action.name
              }
            }
          }
        }
      }

    case NIQ_REMOVE_STAGED_EDIT:
      const updatedStagedEdits = state.description.stagedEdits
      delete updatedStagedEdits[action.key][action.attribute]
      if (isEmpty(updatedStagedEdits[action.key])) {
        delete updatedStagedEdits[action.key]
      }
      return {
        ...state,
        description: {
          ...state.description,
          stagedEdits: { ...updatedStagedEdits }
        }
      }

    case NIQ_UPDATE_AGGREGATION:
      return {
        ...state,
        [action.data.aggType]: {
          ...state[action.data.aggType],
          ...action.data.aggregationUpdates
        }
      }

    case NIQ_SEARCH_DESCRIPTION_SELECTION_CHANGED:
      return {
        ...state,
        description: {
          ...state.description,
          [action.attribute]: action.value
        }
      }

    case NIQ_SORT_TABLE:
      return {
        ...state,
        [action.data.aggType]: {
          ...state[action.data.aggType],
          sortBy: action.data.sortBy,
          sortOrder: action.data.sortOrder
        }
      }

    case NIQ_TIME_AGGREGATION_CHANGED:
      return {
        ...state,
        time: {
          ...state[constants.aggregations.TIME],
          selectedFilter: action.data.selectedFilter,
          selectedInterval: action.data.selectedInterval
        }
      }

    case NIQ_TIME_DIMENSION_CHANGED:
      return {
        ...state,
        time: {
          ...state[constants.aggregations.TIME],
          selectedField: action.data.axis
        }
      }

    case NIQ_DIMENSION_CHANGED:
      return {
        ...state,
        [action.data.aggType]: {
          ...state[action.data.aggType],
          selectedField: action.data.axis
        }
      }

    case NIQ_VIEW_CHANGED:
      return {
        ...state,
        [action.data.aggType]: {
          ...state[action.data.aggType],
          selectedView: action.data.view
        }
      }

    case NIQ_QUERY_STATS_STARTED:
      return handleStatsQuery(action, state)

    case NIQ_QUERY_STATS_SUCCEEDED:
      return handleStatsQuery(action, state)

    case NIQ_QUERY_STATS_FAILED:
      return handleStatsQuery(action, state)

    case NIQ_START_LOADING_WHEEL:
      return {
        ...state,
        [action.aggType]: { ...state[action.aggType], loading: true }
      }

    case NIQ_QUERY_RUN_STARTED:
      let aggType = { ...state[action.aggType], loading: true }

      if (!action.isTemp) {
        const emptyDataAggregations = [constants.aggregations.TIME, constants.aggregations.DICTIONARY_COVERAGE]
        aggType.data = emptyDataAggregations.includes(action.aggType) ? {} : []
      }

      if (action.aggType === constants.aggregations.DESCRIPTION) {
        aggType = { ...aggType, ...defaultEdits }
        if (action.isTemp) {
          aggType.tempFilters = state.description.tempFilters
          aggType.stagedEdits = state.description.stagedEdits
          aggType.trackedEdits = state.description.trackedEdits
        }
      }

      return { ...state, [action.aggType]: aggType }

    case NIQ_QUERY_RUN_SUCCEEDED:
      return {
        ...state,
        [action.aggType]: {
          ...state[action.aggType],
          data: action.data[action.aggType] || {},
          hits: action.isTemp ? state[constants.aggregations.DESCRIPTION].hits : action.data.hits,
          loading: action.loading
        }
      }

    case NIQ_QUERY_RUN_FAILED:
      return {
        ...state,
        [action.aggType]: {
          ...state[action.aggType],
          loading: action.loading
        },
        errorMessage: 'Query Failed'
      }

    case NIQ_QUERY_SET_QUALITY:
      return {
        ...state,
        [action.aggType]: {
          ...state[action.aggType],
          isQueryCorrect: action.isQueryCorrect
        }
      }

    case NIQ_DESCRIPTION_EDIT_STATUS_UPDATED:
      let descriptionData = state.description.data

      // updating the descriptionData with new values if necessary
      if (descriptionData && descriptionData.length) {
        for (const key in action.data) {
          if (action.data.hasOwnProperty(key)) {
            const value = action.data[key]
            // update brand values
            descriptionData = updateDescription(descriptionData, key, value.brand, 'brand_id', 'brand_name')
            // update category values
            descriptionData = updateDescription(
              descriptionData,
              key,
              value.category,
              'category_id',
              'category_full_path'
            )
          }
        }
      }
      return {
        ...state,
        description: {
          ...state.description,
          trackedEdits: merge({}, state.description.trackedEdits, action.data),
          data: descriptionData
        }
      }

    case NIQ_CHANGE_TOOLBAR_OPTION:
      return {
        ...state,
        description: {
          ...state.description,
          showToolbar: !state.description.showToolbar
        }
      }

    case NIQ_APPLY_TEMP_FILTER:
      return {
        ...state,
        description: {
          ...state.description,
          tempFilters: {
            ...state.description.tempFilters,
            [action.aggType]: action.value
          }
        }
      }

    case NIQ_CHANGE_SEARCH_COLUMNS_VISIBILITY:
      const columns = state.description.columns
      const index = columns.indexOf(action.column)
      if (index === -1) {
        columns.push(action.column)
      } else {
        columns.splice(index, 1)
      }
      return {
        ...state,
        description: {
          ...state.description,
          columns: [...columns]
        }
      }

    case NIQ_REMOVE_TEMP_FILTER:
      const tempFilters = { ...state.description.tempFilters }
      delete tempFilters[action.aggType]
      return {
        ...state,
        description: {
          ...state.description,
          tempFilters
        }
      }

    case NIQ_WIDGETS_OPTIONS_CHANGED:
      return {
        ...state,
        [action.aggType]: {
          ...state[action.aggType],
          [action.attribute]: action.value
        }
      }

    case NIQ_STAT_FILTER_CHANGED:
      return {
        ...state,
        stats: {
          ...state.stats,
          selectedFilter: action.selectedFilter
        }
      }

    case LOGOUT_SUCCESS:
      return initialSearchEditState

    case NIQ_FETCH_DESCRIPTION_EDIT_SUGGESTIONS:
      return {
        ...state,
        description: {
          ...state.description,
          loading: true
        }
      }

    case NIQ_FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED:
    case NIQ_FETCH_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED: {
      const stagedEdits = { ...state.description.stagedEdits } || {}
      const { data = {}, loading } = action
      Object.keys(data).forEach(descriptionId => {
        stagedEdits[descriptionId] = stagedEdits[descriptionId] || {}
        stagedEdits[descriptionId].brand = {
          id: data[descriptionId].brandId,
          name: data[descriptionId].brandName
        }
      })
      return {
        ...state,
        description: {
          ...state.description,
          ...defaultEdits,
          stagedEdits: { ...stagedEdits },
          trackedEdits: state.description.trackedEdits,
          loading
        }
      }
    }

    case NIQ_FETCH_DESCRIPTION_EDIT_SUGGESTIONS_FAILED:
      return {
        ...state,
        description: {
          ...state.description,
          loading: false
        }
      }

    default: {
      return state
    }
  }
}

export default searchEditReducer

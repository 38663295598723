import React from 'react'
import PropTypes from 'prop-types'

const PrimaryButton = ({ label, onClickHandler, isDisabled = false, className }) => {
  return (
    <div className={className}>
      <button disabled={isDisabled} className={'btn_custom'} onClick={onClickHandler}>
        {label}
      </button>
    </div>
  )
}

PrimaryButton.propTypes = {
  label: PropTypes.string,
  onClickHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string
}

export default PrimaryButton

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import DocumentTitle from 'react-document-title'

import {
  replicateReport,
  deleteReport,
  fetchReports,
  customReportsSearchEntityChanged
} from '../../actions/custom-report-actions'
import UserService from '../../services/user-service'
import { appName, products, default as CONSTANTS } from '../../constants/constants'
import Unauthorized from '../Unauthorized'
import RVTable from '../Table/ReactVirtualizedTable'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import { confirmModal, confirmModalReport } from '../../actions/modal-actions'
import { updateFilter } from '../../actions/filter-actions'
import { orchestrate } from '../../actions/task-actions'
import './index.scss'

const DATE_FORMAT = CONSTANTS.dateFormat.reportDate

class ReportsManagerPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportFilters: {
        currentSearch: '',
        byReportName: {
          value: '',
          error: ''
        },
        byFolderName: {
          value: '',
          error: ''
        }
      },
      sortBy: 'name'
    }
  }

  /**
   * wait for the user from the session to be updated before rendering component.
   * fetch the reports associated with the group of the user logged in.
   */
  componentDidMount() {
    const { user } = this.props
    if (!user || !(user && user.Groups)) {
      return
    }
    this.props.fetchReports(0, 30, '')
  }

  openSelectedReportForView = report => {
    this.props.history.push(`/admin/reports/view/${report.id}`)
  }

  openSelectedReportForEdit = report => {
    this.props.history.push(`/admin/reports/edit/${report.id}`)
  }

  replicateSelectedReport = (report, index) => {
    const { replicateReport, reportGroupId } = this.props
    let replicaReportName = ``
    if (report.name.indexOf('Copy -') > 0) {
      const existingReplicaName = report.name.slice(0, report.name.lastIndexOf('- '))
      replicaReportName = `${existingReplicaName} - ${moment().format('YYYY/MM/DD h:mm:ss')}`
    } else {
      replicaReportName = `${report.name} Copy - ${moment().format('YYYY/MM/DD h:mm:ss')}`
    }
    const replicaReport = { ...report, name: replicaReportName }
    replicateReport({
      report: replicaReport,
      groupId: reportGroupId
    })
  }

  closeModal = () => {
    this.setState({ openModal: false, operation: '', deleteObject: {} })
  }

  _deleteReport = () => {
    this.closeModal()
  }

  deleteSelectedReport = (report, index) => {
    const { deleteReport, reportGroupId } = this.props
    deleteReport({
      report,
      groupId: reportGroupId
    })
  }

  getReportsByFilter(filters) {
    const { groupReports } = this.props
    const reportFilters = filters || this.state.reportFilters
    const filteredReports = groupReports.filter(report => {
      if (reportFilters.byReportName.value === '' && reportFilters.byFolderName.value === '') {
        return report
      } else if (
        report.name.toLowerCase().indexOf(reportFilters.byReportName.value) > -1 &&
        report.folderName.toLowerCase().indexOf(reportFilters.byFolderName.value) > -1
      ) {
        return report
      }
      return false
    })
    return filteredReports
  }

  getReportsData = () => {
    const filteredReports = this.getReportsByFilter()
    const data = filteredReports.map(report => {
      return {
        reportName: report.name,
        folderName: report.folderName,
        createdBy: report.createdBy,
        updatedBy: report.updatedBy,
        createdOn: report.createdAt,
        actions: report
      }
    })
    return data
  }

  isUnauthorized = key => {
    return !this.props.userProducts || !this.props.userProducts[key] || !this.props.userProducts[key].enabled
  }

  getCustomReportColumns = () => {
    let count = 0
    return [
      {
        key: 'reportName',
        label: 'Report Name',
        cellRenderer: report => <span>{report.reportName}</span>
      },
      {
        key: 'folderName',
        label: 'Folder Name',
        width: 70
      },
      {
        key: 'createdBy-updatedBy',
        label: 'Created By / Updated By',
        cellRenderer: report => {
          if (!report.createdBy && !report.updatedBy) return null
          const UNKNOWN = 'Unknown'
          return `${report.createdBy || UNKNOWN} / ${report.updatedBy || UNKNOWN}`
        },
        width: 70
      },
      {
        key: 'createdOn',
        label: 'Created On',
        cellRenderer: report => report.createdOn && moment(report.createdOn).format(DATE_FORMAT),
        width: 70
      },
      {
        key: 'actions',
        label: 'Actions',
        width: 160,
        cellRenderer: report => {
          const elementIndex = ++count
          return (
            <div className="reports-manager-action-column">
              <span className="actionButton" onClick={() => this.openSelectedReportForView(report.actions)}>
                View
              </span>
              <span className="actionButton" onClick={() => this.openSelectedReportForEdit(report.actions)}>
                Edit
              </span>
              <span className="actionButton" onClick={() => this.replicateSelectedReport(report.actions, elementIndex)}>
                Replicate
              </span>
              <span className="actionButton" onClick={() => this.refreshFilterValue(report.actions.id)}>
                Refresh Filters
              </span>
              <span
                className="actionButton"
                onClick={event => {
                  event.preventDefault()
                  this.props.confirmModalReport(
                    `Are you sure you want to delete report \n${report.reportName}?`,
                    { okButton: 'Yes' },
                    () => {
                      this.deleteSelectedReport(report.actions, elementIndex - 1)
                    },
                    'medium'
                  )
                }}
              >
                Delete
              </span>
            </div>
          )
        }
      }
    ]
  }

  refreshFilterValue = reportId => {
    const payload = {
      userId: this.props.user ? this.props.user.id : null
    }
    if (reportId) {
      payload.reportId = reportId
      payload.frequency = 'once'
    }
    return this.props.confirmModal(
      <span>
        {`Filter values are refreshed by the system automatically at a certain frequency, usually daily. Are you sure you want to manually refresh all the filter values ${
          reportId ? 'of this report' : ''
        }?`}
      </span>,
      'Confirm Orchestration',
      { okButton: 'Yes, Orchestrate' },
      () => this.props.orchestrateRefreshFiltersTask('filter-values-load', payload)
    )
  }

  render() {
    if (this.isUnauthorized(products.adminToolsCustomReportManager)) {
      return <Unauthorized />
    }
    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <div className="container reports-manager-page">
          <div className={'textRight'} style={{ paddingBottom: '20px' }}>
            <button
              className={'btn_custom'}
              type="button"
              onClick={event => {
                event.preventDefault()
                this.refreshFilterValue()
              }}
            >
              Refresh Filter Values
            </button>
            <Link className={'btn_custom'} to="/admin/reports/edit/new">
              Create New Report
            </Link>
          </div>
          <div className="reports-table">
            <RVTable
              columns={this.getCustomReportColumns()}
              data={this.getReportsData()}
              query={this.props.query}
              fetchData={this.props.fetchReports}
              enableSearch
              searchEntities={this.props.searchEntities}
              remoteRowCount={this.props.customReportsCount}
              selectedSearchEntity={this.props.selectedSearchEntity}
              search={this.props.search}
              updateFilter={this.props.updateFilter}
              minimumBatchSize={25}
              onSearchEntityChanged={this.props.customReportsSearchEntityChanged}
            />
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

ReportsManagerPage.propTypes = {
  isOnlyGroupAdmin: PropTypes.bool,
  user: PropTypes.object,
  reports: PropTypes.object,
  groupReports: PropTypes.array,
  replicateReport: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
  userProducts: PropTypes.object,
  reportGroupId: PropTypes.number,
  fetchReports: PropTypes.func.isRequired,
  customReportsCount: PropTypes.number,
  searchEntities: PropTypes.object,
  selectedSearchEntity: PropTypes.string,
  customReports: PropTypes.string,
  search: PropTypes.string,
  updateFilter: PropTypes.func,
  customReportsSearchEntityChanged: PropTypes.func,
  query: PropTypes.object,
  orchestrateRefreshFiltersTask: PropTypes.func,
  confirmModal: PropTypes.func,
  confirmModalReport: PropTypes.func,
  history: PropTypes.object.isRequired,
  documentTitle: PropTypes.string
}

ReportsManagerPage.defaultProps = {
  documentTitle: 'Reports Manager'
}

function mapStateToProps(state, props) {
  return {
    user: state.session.user,
    reportGroupId:
      state.session.user && state.session.user.Groups
        ? state.session.user.Groups[UserService.getAdminIndex(state.session.user.Groups)].id
        : -1,
    groupReports: state.reports.data,
    isOnlyGroupAdmin:
      state.session.user &&
      !UserService.isPortalAdmin(state.session.user) &&
      UserService.isGroupAdmin(state.session.user),
    userProducts: UserService.getProducts(state.session.user),
    customReportsCount: state.reports.count,
    searchEntities: state.reports.searchEntities,
    selectedSearchEntity: state.reports.selectedSearchEntity,
    search: state.filter.reportsName,
    query: props.location.query
  }
}

function mapDispatchToProps(dispatch) {
  return {
    replicateReport: payload => dispatch(replicateReport({ payload })),
    deleteReport: payload => dispatch(deleteReport({ payload })),
    fetchReports: (offset, count, search) => {
      if (search !== null) dispatch(fetchReports(offset, count, search))
    },
    updateFilter: (text, id) => dispatch(updateFilter(text, 'reportsName')),
    customReportsSearchEntityChanged: selected => dispatch(customReportsSearchEntityChanged(selected)),
    orchestrateRefreshFiltersTask: (jobType, metdata) => dispatch(orchestrate(jobType, metdata)),
    confirmModal: (content, title, okButton, callback) => dispatch(confirmModal(content, title, okButton, callback)),
    confirmModalReport: (content, okButton, callback, size) =>
      dispatch(confirmModalReport(content, okButton, callback, size))
  }
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, mapDispatchToProps)(ReportsManagerPage),
  '"Admin Reports Manager" page'
)

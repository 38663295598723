import React from 'react'
import cx from 'classnames'

import MenuItem from './MenuItem'

import HomeIcon from 'components/Icon/Home'

import ReportsIcon from 'components/Icon/Reports'
import ReportsSubMenu from './SubMenu/ReportsSubMenu'

import CMEIcon from 'components/Icon/CME'
import CMESubMenu from './SubMenu/CMESubMenu'

import SupraIcon from 'components/Icon/Supra'
import SupraSubMenu from './SubMenu/SupraSubMenu'

import NIQIcon from 'components/Icon/NIQIcon'
import NIQSubMenu from './SubMenu/NIQSubMenu'

import AdminIcon from 'components/Icon/Admin'
import AdminSubMenu from './SubMenu/AdminSubMenu'

import usePermissions from './usePermissions'
import useLeftNav, { LeftNavProvider } from './leftNavContext'

import './leftNav.scss'

/**
 * Main Navigation component for Application
 */
const LeftNav = () => {
  const { isOpen, openMenu, closeMenu } = useLeftNav()

  const { isPortalAdmin, isGroupAdmin, hasAdminToolsAccess, hasReportsAccess, hasNIQqcToolAccess } = usePermissions()

  const isRIOnly = window.Tenant === 'ri'

  const menuItems = [
    {
      label: 'Dashboard',
      icon: HomeIcon,
      rootPath: 'dashboard',
      route: '/dashboard',
      isAuthorized: isRIOnly
    },
    {
      label: 'Reports',
      icon: ReportsIcon,
      rootPath: 'custom',
      className: 'sideBarReport',
      subMenu: ReportsSubMenu,
      isAuthorized: hasReportsAccess
    },
    {
      label: 'CME',
      icon: CMEIcon,
      rootPath: 'cme',
      subMenu: CMESubMenu,
      isAuthorized: (isPortalAdmin || isGroupAdmin || hasAdminToolsAccess) && isRIOnly
    },
    {
      label: 'Supra',
      icon: SupraIcon,
      rootPath: 'supra',
      subMenu: SupraSubMenu,
      isAuthorized: (isPortalAdmin || isGroupAdmin || hasAdminToolsAccess) && isRIOnly
    },
    {
      label: 'NIQ',
      icon: NIQIcon,
      rootPath: 'niq',
      subMenu: NIQSubMenu,
      isAuthorized: hasNIQqcToolAccess && isRIOnly
    },
    {
      label: 'Admin Tools',
      icon: AdminIcon,
      rootPath: 'admin',
      subMenu: AdminSubMenu,
      isAuthorized: isPortalAdmin || isGroupAdmin || hasAdminToolsAccess
    }
  ]

  return (
    <div className="sidebar-wrapper">
      <ul className={cx('sidebar-nav', { 'sidebar-nav-open': isOpen })} onClick={openMenu} onMouseLeave={closeMenu}>
        {menuItems.map(menu =>
          menu.isAuthorized ? (
            <MenuItem
              key={menu.rootPath}
              label={menu.label}
              icon={menu.icon}
              className={menu.className}
              route={menu.route}
              rootPath={menu.rootPath}
              subMenu={menu.subMenu}
              isBeta={menu.isBeta}
            />
          ) : null
        )}
      </ul>
    </div>
  )
}

LeftNav.propTypes = {}

const LeftNavWithContext = () => {
  return (
    <LeftNavProvider>
      <LeftNav />
    </LeftNavProvider>
  )
}
export default LeftNavWithContext

import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ClearButtonIcon from '../Icon/Close'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import config from '../../config'
import cx from 'classnames'

import './index.scss'
/*!
 * globals
 */

const noop = () => {}

const InputBox = ({
  textDidChange,
  className,
  placeholder,
  iconName,
  type,
  autoFocus,
  value,
  clearButton,
  tabIndex,
  didClickClearButton
}) => {
  const [borderColor, setBorderColor] = useState({})
  const inputBoxWrapperRef = useRef(null)

  const onTextChange = event => {
    return textDidChange(event.target.value)
  }

  const onClickHandler = () => {
    setBorderColor({ borderColor: config.colors.primaryColor })
  }

  const onBlurHandler = _event => {
    setBorderColor({ borderColor: {} })
  }

  const tooltip = name => (
    <Tooltip id={name}>
      <div className="tooltip-box">{name}</div>
    </Tooltip>
  )

  return (
    <OverlayTrigger rootClose placement="top" overlay={tooltip(placeholder)}>
      <div className={cx(className)} ref={inputBoxWrapperRef} style={borderColor}>
        {iconName}
        <input
          onChange={onTextChange}
          onClick={onClickHandler}
          onBlur={onBlurHandler}
          placeholder={placeholder}
          type={type}
          value={value}
          autoFocus={autoFocus}
          tabIndex={tabIndex}
        />
        {clearButton && value && (
          <ClearButtonIcon
            onClick={didClickClearButton}
            width={24}
            height={24}
            style={{
              position: 'absolute',
              right: '3px',
              bottom: '3px'
            }}
          />
        )}
      </div>
    </OverlayTrigger>
  )
}

InputBox.propTypes = {
  type: PropTypes.oneOf(['password', 'text']),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  textDidChange: PropTypes.func,
  didClickClearButton: PropTypes.func,
  iconName: PropTypes.element,
  clearButton: PropTypes.bool,
  tabIndex: PropTypes.string
}

InputBox.defaultProps = {
  type: 'text',
  value: '',
  autoFocus: false,
  placeholder: '',
  textDidChange: noop,
  didClickClearButton: noop,
  clearButton: false
}

export default InputBox

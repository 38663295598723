import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, MenuItem, Select } from '@mui/material'

const NO_VALUE = ''

function BooleanValueEditor({ initialValue, onChange }) {
  const [value, setValue] = useState(initialValue)

  const handleChange = event => {
    const selectedValue = event.target.value === 'true'
    setValue(selectedValue)
    onChange([{ value: selectedValue }])
  }

  const valueAsString = value === true ? 'true' : value === false ? 'false' : NO_VALUE

  return (
    <div className="description-value-editor">
      <FormControl sx={{ m: 0, width: 150 }}>
        <Select
          defaultOpen
          id="value-editor"
          data-testid="value-editor-test"
          value={valueAsString}
          onChange={handleChange}
        >
          <MenuItem key="true" value="true">
            True
          </MenuItem>
          <MenuItem key="false" value="false">
            False
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

BooleanValueEditor.propTypes = {
  initialValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

export default BooleanValueEditor

import { has, keys } from 'lodash'

import { PERMISSIONS_DEPENDENCIES } from 'constants/constants'
import { PERMISSION_NOT_DEFINED_VALUE } from './permission-constant'

/**
 * This function select lower permission when high is selected
 * and unselect high permission when low is unselected
 * @param {Object} param0 - The object with key and value
 * @param {string} param0.key - The key of the permission
 * @param {boolean} param0.val - The value of the permission
 * @param {Array<Array<string>>} param0.productsPermissions - The array of products permissions dependencies
 * @returns {Object} - The object with updated permissions
 */
export const updateRelativePermissions = ({ key, val, productsPermissions = PERMISSIONS_DEPENDENCIES }) => {
  const product = productsPermissions.find(el => el.includes(key))
  if (!product) return {}
  let defaultFromLeft = true

  switch (val) {
    case true:
      let defaultFromRight = false
      return product.reduceRight((acc, permission) => {
        if (key === permission) {
          defaultFromRight = true
        }
        return { ...acc, [permission]: defaultFromRight }
      }, {})
    case false:
      return product.reduce((acc, permission) => {
        if (key === permission) {
          defaultFromLeft = false
        }
        return { ...acc, [permission]: defaultFromLeft }
      }, {})
    case PERMISSION_NOT_DEFINED_VALUE:
      return product.reduce((acc, permission) => {
        if (key === permission) {
          defaultFromLeft = PERMISSION_NOT_DEFINED_VALUE
        }
        return { ...acc, [permission]: defaultFromLeft }
      }, {})
    default:
      return {}
  }
}

/**
 * Calculate actual permissions value based on the current state and new values
 * @param {Object.<string, Object} permissions - The object with permissions
 * @param {Object.<string, boolean|string>} newPermissions - The object with new permissions
 * @param {boolean} defaultValue - The default value of the permission
 * @returns {Array<Object>} - The array of permissions
 */
export const calculatePermissions = ({ permissions, newPermissions, defaultValue = false }) => {
  const permissionKeys = keys(permissions)

  return permissionKeys.map(key => {
    return {
      ...permissions[key],
      value: has(newPermissions, key) ? newPermissions[key] : defaultValue
    }
  })
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LicenseInfo } from '@mui/x-license'

import getChildRoute from './routes'

// importing App.scss before components & pages so that they can override it later
import './App.scss'

// components
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'
import Loader from './components/Loader'
import Header from './components/Header'
import LeftNav from './components/LeftNav'
import UserEditPage from './components/UserEditPage'
import Walkthrough from './components/Walkthrough'
import Footer from './components/Footer'

// actions
import { login, logout, logoutSuccess } from './actions/auth-actions'

// selectors
import { getAuth, isAuthValid } from './selectors/session-selectors'

import AuthService from './services/auth-service'
import { REACT_APP_MUI_LICENSE_KEY } from './constants/constants'

const APP_VERSION = process.env.REACT_APP_VERSION

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE_KEY)

const App = ({
  auth,
  login,
  logoutSuccess,
  isAuthValid,
  pathname,
  user,
  match,
  logout,
  loading,
  beacons,
  isWalkthroughEnabled
}) => {
  useEffect(() => {
    if (auth && isAuthValid && pathname !== '/onboarding' && pathname !== '/reset_password') {
      login({ auth })
    }
  }, [auth, isAuthValid, pathname, login])

  useEffect(() => {
    // Clear session state and redirect if logged out from another tab. Event triggered in auth-service
    window.addEventListener('storage', event => {
      if (event.key === 'logout-event') {
        console.info('A logout event has been detected')
        logoutSuccess()
        AuthService.redirectToLogin(history.replace, window.location)
      }
    })
  }, [logoutSuccess])

  return (
    <div className="app">
      {<Header user={isAuthValid ? user : null} onLogout={logout} pathname={pathname} />}
      {user && isAuthValid && user.isOnboarded && <LeftNav />}
      <div className="page-content">
        {(() => {
          if (!user && loading) {
            return (
              <div className="page-container">
                <Loader />
              </div>
            )
          } else if (user && !user.isOnboarded) {
            return <UserEditPage match={match} />
          } else {
            return getChildRoute()
          }
        })()}
        <Footer appVersion={APP_VERSION} isAuthValid={isAuthValid} />
      </div>
      {user && isWalkthroughEnabled && user.isOnboarded && <Walkthrough beacons={beacons} pathname={pathname} />}
    </div>
  )
}

App.propTypes = {
  onMount: PropTypes.func,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  logoutSuccess: PropTypes.func.isRequired,
  user: PropTypes.object,
  sessionState: PropTypes.string,
  auth: PropTypes.object,
  match: PropTypes.object,
  isAuthValid: PropTypes.bool.isRequired,
  children: PropTypes.element,
  loading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  beacons: PropTypes.object,
  pathname: PropTypes.string,
  isWalkthroughEnabled: PropTypes.bool
}

// Connect
const mapStateToProps = state => ({
  loading: state.session.loading,
  user: state.session.user,
  auth: getAuth(state),
  isAuthValid: isAuthValid(state),
  isWalkthroughEnabled: state.walkthrough.isEnabled,
  pathname: state.router.location.pathname || ''
})

const mapDispatchToProps = dispatch => ({
  login: auth => dispatch(login(auth)),
  logout: () => dispatch(logout()),
  logoutSuccess: () => dispatch(logoutSuccess())
})

export default WithErrorBoundaryWrapper(connect(mapStateToProps, mapDispatchToProps)(App))

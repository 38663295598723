import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Loader from '../Loader/Loader'
import Table from '../Table/ReactVirtualizedTable'
import { fetchProducts, fetchGroupProducts } from '../../actions/app-actions'
import { connect } from 'react-redux'
import { keys, pickBy } from 'lodash'
import { products as PRODUCTS } from '../../constants/constants'

// Only show products that are assignable (Groups and users are not assignable)
const validProducts = [
  PRODUCTS.adminToolsCustomReportManager,
  PRODUCTS.adminToolsFeeds,
  PRODUCTS.adminToolsQCTool,
  PRODUCTS.adminToolsSupraQCTool,
  PRODUCTS.adminToolsJobMonitoring,
  PRODUCTS.adminToolsDeliveryManager,
  PRODUCTS.NIQqcTool
]

const ProductTable = ({
  loading,
  fetchProducts,
  fetchGroupProducts,
  groupId,
  isPortalGroup,
  registerChanges,
  products,
  groupProducts
}) => {
  const [isCheck, setIsCheck] = useState({})

  useEffect(() => {
    fetchProducts()

    if (groupId && groupId !== 'new') {
      fetchGroupProducts(groupId)
    }
  }, [groupId, fetchProducts, fetchGroupProducts])

  useEffect(() => {
    const checked = {}
    // New Group
    if (groupId === 'new') {
      keys(products).forEach(key => {
        checked[key] = false
      })
    } else {
      // Existing Group
      keys(products).forEach(key => {
        checked[key] = groupProducts && groupProducts[key] ? groupProducts[key].enabled : false
      })
    }
    setIsCheck(st => {
      return {
        ...st,
        ...checked
      }
    })
  }, [groupId, groupProducts])

  const onCheckedValueChange = (key, val) => {
    const newState = { [key]: val }
    setIsCheck(st => ({ ...st, ...newState }))
    registerChanges(newState)
  }

  const getData = () => {
    return keys(products).map(key => {
      return {
        ...products[key],
        enabled: (isCheck && isCheck[key]) ?? (groupProducts && groupProducts[key]?.enabled)
      }
    })
  }

  const getColumns = () => {
    const columns = ['name', 'description', 'key', 'enabled']
    return columns.map(column => {
      return {
        key: column,
        label: column,
        type: column === 'enabled' ? 'checkbox' : ''
      }
    })
  }

  if (isPortalGroup) {
    return <span className="group-note">This is a Portal Group. You cannot edit settings for this group.</span>
  }
  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <div>
        <Table
          data={getData()}
          columns={getColumns()}
          fetchData={fetchProducts}
          remoteRowCount={products ? products.length : 0}
          searchEntity={'Products'}
          onCheckedValueChange={onCheckedValueChange}
          height={400}
          enableSearch={false}
        />
      </div>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    groupProducts:
      state.session && state.session.groupProducts && state.session.groupProducts[ownProps.groupId]
        ? state.session.groupProducts[ownProps.groupId] || {}
        : {},
    products:
      state.session && state.session.products
        ? pickBy(state.session.products, product => validProducts.includes(product.key))
        : {}
  }
}

ProductTable.propTypes = {
  products: PropTypes.object,
  groupProducts: PropTypes.object,
  columns: PropTypes.array,
  fetchProducts: PropTypes.func,
  registerChanges: PropTypes.func,
  clear: PropTypes.bool,
  loading: PropTypes.bool,
  groupId: PropTypes.string,
  isPortalGroup: PropTypes.bool,
  fetchGroupProducts: PropTypes.func
}

export default connect(mapStateToProps, { fetchProducts, fetchGroupProducts })(ProductTable)

import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import fallBack from './fallBack'

/*
 *  We use this wrapper to catch all errors on the Page (component),
 *  including errors in business logic & API errors
 */
function WithErrorBoundaryWrapper(WrappedComponent, pageName) {
  const _pageName = pageName || 'Application'
  const fallBackExpanded = fallBack.bind(null, _pageName)

  return function WithError(props) {
    return (
      <ErrorBoundary fallbackRender={fallBackExpanded}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    )
  }
}

WithErrorBoundaryWrapper.propTypes = {
  WrappedComponent: PropTypes.node,
  pageName: PropTypes.string
}

export default WithErrorBoundaryWrapper
